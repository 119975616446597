import React, { useState }  from 'react';
import '../App.css';
import { useTranslation } from 'react-i18next';
import Graph from "react-graph-vis";
 
const graphGross = {
  nodes: [
    { id: 1, label: "K1", color: "#e04141" },
    { id: 2, label: "K2", color: "#e09c41" },
    { id: 3, label: "K3", color: "#e0df41" },
    { id: 4, label: "K4", color: "#7be041" },
    { id: 5, label: "K5", color: "#41e0c9" },
    { id: 6, label: "K6", color: "#7be041" },
    { id: 7, label: "K7", color: "#e09c41" },
    { id: 8, label: "K8", color: "#41e0c9" },
    { id: 9, label: "K9", color: "#41e0c9" },
  ],
  edges: [
    { from: 1, to: 2 },
    { from: 1, to: 3 },
    { from: 1, to: 4 },
    { from: 2, to: 3 },
    { from: 3, to: 5 },
    { from: 3, to: 7 },
    { from: 3, to: 9 },
    { from: 5, to: 7 },
    { from: 4, to: 8 },
    { from: 6, to: 4 },
    { from: 8, to: 3 },
    { from: 6, to: 8 }
  ]
};
const graphMittel = {
  nodes: [
    { id: 1, label: "K1", color: "#e04141" },
    { id: 2, label: "K2", color: "#e09c41" },
    { id: 3, label: "K3", color: "#e0df41" },
    { id: 4, label: "K4", color: "#7be041" },
    { id: 5, label: "K5", color: "#41e0c9" },
    { id: 6, label: "K6", color: "#7be041" },
    { id: 7, label: "K7", color: "#e09c41" },
  ],
  edges: [
    { from: 1, to: 2 },
    { from: 1, to: 3 },
    { from: 1, to: 4 },
    { from: 2, to: 3 },
    { from: 3, to: 5 },
    { from: 3, to: 7 },
    { from: 5, to: 7 },
    { from: 6, to: 4 },
  ]
};
const graphKlein = {
  nodes: [
    { id: 1, label: "K1", color: "#e04141" },
    { id: 2, label: "K2", color: "#e09c41" },
    { id: 3, label: "K3", color: "#e0df41" },
    { id: 4, label: "K4", color: "#7be041" },
    { id: 5, label: "K5", color: "#41e0c9" },
  ],
  edges: [
    { from: 1, to: 2 },
    { from: 1, to: 3 },
    { from: 1, to: 4 },
    { from: 2, to: 3 },
    { from: 3, to: 5 },
  ]
};
const EX5 = ({place, level, doneexercises, setDoneexercises, doneplaces, setDoneplaces}) => {
  const { t } = useTranslation();
  const [darstellung, setDarstellung] = useState(0);
  const [antwort, setAntwort] = useState(0);

  const TauschhandelKlein = () => {
    const options = {
      layout: {
        hierarchical: false
      },
      edges: {
        color: "#000000"
      }
    };
    
    return (
      <div>
        <Graph graph={graphKlein} options={options} style={{ height: "500px", width:"500px", backgroundColor:"lightgray", borderRadius:"20px", borderColor:"black", borderStyle:"solid"  }} />
      </div>
      );
    }
    const TauschhandelMittel = () => {
      const options = {
        layout: {
          hierarchical: false
        },
        edges: {
          color: "#000000"
        }
      };
      
      return (
        <div>
          <Graph graph={graphMittel} options={options} style={{ height: "500px", width:"500px", backgroundColor:"lightgray", borderRadius:"20px", borderColor:"black", borderStyle:"solid" }}/>
        </div>
        );
      }
      const TauschhandelGross = () => {
        const options = {
          layout: {
            hierarchical: false
          },
          edges: {
            color: "#000000"
          }
        };
        
        return (
          <div>
            <Graph graph={graphGross} options={options} style={{ height: "500px", width:"500px", backgroundColor:"lightgray", borderRadius:"20px", borderColor:"black", borderStyle:"solid"  }} />
          </div>
          );
        }
        const Tabeletry = () => {
          return (
           <table style={{width: '200px', height: '200px'}}>
            <tr>
              <td></td>
              <td>K1</td>
              <td>K2</td>
              <td>K3</td>
              <td>K4</td>
              <td>K5</td>
              {level >= 1 && <td>K6</td>}
              {level >= 1 && <td>K7</td>}
              {level === 2 && <td>K8</td>}
              {level === 2 && <td>K9</td>}
            </tr>
            <tr>
              <td>K1</td>
              <td></td>
              <td style={{color: antwort===1 && 'red', fontWeight: antwort===1 && 'bold'}}>x</td>
              <td>x</td>
              <td>x</td>
              <td></td>
              {level >= 1 && <td></td>}
              {level >= 1 && <td></td>}
              {level === 2 && <td></td>}
              {level === 2 && <td></td>}
            </tr>
            <tr>
              <td>K2</td>
              <td></td>
              <td></td>
              <td>x</td>
              <td></td>
              <td></td>
              {level >= 1 && <td></td>}
              {level >= 1 && <td></td>}
              {level === 2 && <td></td>}
              {level === 2 && <td></td>}
            </tr>
            <tr>
              <td>K3</td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td style={{color: antwort===2 && 'red', fontWeight: antwort===2 && 'bold'}}>x</td>
              {level >= 1 && <td></td>}
              {level >= 1 && <td>x</td>}
              {level === 2 && <td></td>}
              {level === 2 && <td style={{color: antwort===1 && 'red', fontWeight: antwort===1 && 'bold'}}>x</td>}
            </tr>
            <tr>
              <td>K4</td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              {level >= 1 && <td></td>}
              {level >= 1 && <td></td>}
              {level === 2 && <td>x</td>}
              {level === 2 && <td></td>}
            </tr>
            <tr>
              <td>K5</td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              {level >= 1 && <td></td>}
              {level >= 1 && <td>x</td>}
              {level === 2 && <td></td>}
              {level === 2 && <td></td>}
            </tr>
            {level>=1 &&<tr>
                <td>K6</td>
                <td></td>
                <td></td>
                <td></td>
                <td style={{color: antwort===2 && 'red', fontWeight: antwort===2 && 'bold'}}>x</td>
                <td></td>
                <td></td>
                <td></td>
                {level === 2 && <td>x</td>}
                {level === 2 && <td></td>}
              </tr>}
              {level>=1 &&<tr>
                <td>K7</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                {level === 2 && <td></td>}
                {level === 2 && <td></td>}
              </tr>}
              {level===2 &&<tr>
                <td>K8</td>
                <td></td>
                <td></td>
                <td style={{color: antwort===2 && 'red', fontWeight: antwort===2 && 'bold'}}>x</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>}
            {level===2 &&<tr>
              <td>K9</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>}
              </table>
            );
        }
  const Ruletry2 = () => {
    return (
      <div>
        <div> 
        <ol>
        <li>{t('r2EX5')}</li>
        <li style={{color: antwort===2 && 'red', fontWeight: antwort===2 && 'bold'}}>{t('r5EX5')}</li>
        <li style={{color: antwort===1 && 'red', fontWeight: antwort===1 && 'bold'}}>{t('r1EX5')}</li>
        <li>{t('r3EX5')}</li>
        <li>{t('r4EX5')}</li>
        {level>=1 &&<li>{t('r6EX5')}</li>}
        {level>=1 &&<li>{t('r8EX5')}</li>}
        {level>=1 &&<li style={{color: antwort===2 && 'red', fontWeight: antwort===2 && 'bold'}}>{t('r10EX5')}</li>}
        {level===2 &&<li style={{color: antwort===2 && 'red', fontWeight: antwort===2 && 'bold'}}>{t('r11EX5')}</li>}
        {level===2 && <li>{t('r9EX5')}</li>}
        {level===2 && <li>{t('r12EX5')}</li>}
        {level===2 && <li style={{color: antwort===1 && 'red', fontWeight: antwort===1 && 'bold'}}>{t('r7EX5')}</li>}
        </ol>
      </div>
      </div>
    );
  }
  function correct(){
    graphKlein.edges[0].color="reblackd";
    graphKlein.edges[0].value=undefined;
    graphMittel.edges[0].color="black";
    graphMittel.edges[0].value=undefined;
    graphGross.edges[0].color="black";
    graphGross.edges[0].value=undefined;
    graphGross.edges[6].color="black";
    graphGross.edges[6].value=undefined;
    graphKlein.edges[3].color="black";
    graphKlein.edges[3].value=undefined;
    graphMittel.edges[3].color="black";
    graphMittel.edges[3].value=undefined;
    graphMittel.edges[7].color="black";
    graphMittel.edges[7].value=undefined;
    graphGross.edges[3].color="blackj";
    graphGross.edges[3].value=undefined;
    graphGross.edges[7].color="black";
    graphGross.edges[7].value=undefined;
    graphGross.edges[10].color="black";
    graphGross.edges[10].value=undefined;
    setAntwort(3);
    let prevex = doneexercises;
    prevex[4]= true;
    setDoneexercises(prevex);
    let prevplace = doneplaces;
    prevplace[place]= true;
    setDoneplaces(prevplace);
    window.alert("Good Job!");
  }
  function wrong1(){
    graphKlein.edges[3].color="black";
    graphKlein.edges[3].value=undefined;
    graphMittel.edges[3].color="black";
    graphMittel.edges[3].value=undefined;
    graphMittel.edges[7].color="black";
    graphMittel.edges[7].value=undefined;
    graphGross.edges[3].color="blackj";
    graphGross.edges[3].value=undefined;
    graphGross.edges[7].color="black";
    graphGross.edges[7].value=undefined;
    graphGross.edges[10].color="black";
    graphGross.edges[10].value=undefined;
    if(level ===0){
      graphKlein.edges[0].color="red";
      graphKlein.edges[0].value=1;
    }
    if(level === 1){
      graphMittel.edges[0].color="red";
      graphMittel.edges[0].value=1;
    }
    if (level=== 2){
      graphGross.edges[0].color="red";
      graphGross.edges[0].value=1;
      graphGross.edges[6].color="red";
      graphGross.edges[6].value=1;
    }
   setAntwort(1);
  }

  function wrong2(){
    graphKlein.edges[0].color="reblackd";
    graphKlein.edges[0].value=undefined;
    graphMittel.edges[0].color="black";
    graphMittel.edges[0].value=undefined;
    graphGross.edges[0].color="black";
    graphGross.edges[0].value=undefined;
    graphGross.edges[6].color="black";
    graphGross.edges[6].value=undefined;
    if(level === 0){
      graphKlein.edges[3].color="red";
      graphKlein.edges[3].value=1;
    }
    if(level === 1){
      graphMittel.edges[3].color="red";
      graphMittel.edges[3].value=1;
      graphMittel.edges[7].color="red";
      graphMittel.edges[7].value=1;
    }
    if (level=== 2){
      graphGross.edges[3].color="red";
      graphGross.edges[3].value=1;
      graphGross.edges[7].color="red";
      graphGross.edges[7].value=1;
      graphGross.edges[10].color="red";
      graphGross.edges[10].value=1;
    }
   setAntwort(2);
  }
  
  return (
    <div className='exercises'>
      <p>{t('intrEX5')}</p>
      <div style={{alignItems:'center', justifyContent:'center', display:"flex"}}>
        {darstellung === 0 && <Ruletry2/>}
        {(darstellung === 1 && level===0) && <TauschhandelKlein/>}
        {(darstellung === 1 && level===1) && <TauschhandelMittel/>}
        {(darstellung === 1 && level===2) && <TauschhandelGross/>}
        {darstellung === 2 && <Tabeletry/>}     
      </div>
    <button className= "pretButton" onClick={()=>setDarstellung(0)}>{t('text')}</button>
    <button className= "pretButton" onClick={()=>setDarstellung(1)}>{t('graph')}</button>
    <button className= "pretButton" onClick={()=>setDarstellung(2)}>{t('table')}</button>
    <p>{t('fragEX5')}</p>
    {level === 0 && <ol className="antworten">
        <li><label ><input type="radio" checked={antwort === 1} onClick={()=>wrong1()}/>{t('a1EX5k')}</label></li>
        <li><label ><input type="radio" checked={antwort === 3} onClick={()=>correct()}/>{t('a2EX5k')}</label></li>
        <li><label ><input type="radio" checked={antwort === 2} onClick={()=>wrong2()}/>{t('a3EX5k')}</label></li>
        </ol>} 
    {level === 1 && <ol className="antworten">
        <li><label ><input type="radio" checked={antwort === 1} onClick={()=>wrong1()}/>{t('a1EX5m')}</label></li>
        <li><label ><input type="radio" checked={antwort === 3} onClick={()=>correct()}/>{t('a2EX5m')}</label></li>
        <li><label ><input type="radio" checked={antwort === 2} onClick={()=>wrong2()}/>{t('a3EX5m')}</label></li>
        </ol>}
    {level === 2 && <ol className="antworten">
        <li><label ><input type="radio" checked={antwort === 1} onClick={()=>wrong1()}/>{t('a1EX5g')}</label></li>
        <li><label ><input type="radio" checked={antwort === 3} onClick={()=>correct()}/>{t('a2EX5g')}</label></li>
        <li><label ><input type="radio" checked={antwort === 2} onClick={()=>wrong2()}/>{t('a3EX5g')}</label></li>
        </ol>}
      

    </div>
  );
}

export default EX5;