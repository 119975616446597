import './App.css';
import LangSelector from './components/LangSelector';
import EX0 from './components/EX0';
import EX1 from './components/EX1';
import EX2 from './components/EX2';
import EX3 from './components/EX3';
import EX4 from './components/EX4';
import EX5 from './components/EX5';
import EX6 from './components/EX6';
import EX7 from './components/EX7';
import EX8 from './components/EX8';
import EX9 from './components/EX9';
import Credits from './components/Credits';
import React,{useState} from 'react';

function App() {
  const[exercise,setExercise] = useState(0);
  const[level, setLevel] = useState(0);
  const[place, setPlace] = useState(0);

  const[doneexercises, setDoneexercises] = useState([false,false,false,false,false,false,false,false,false]);
  const[doneplaces, setDoneplaces] = useState([false,false,false,false,false,false,false,false,false]);

  return ( 
    <>
    <div className="app"> 
    <div className="cont"><LangSelector/><button className="homeButton" onClick={()=>setExercise(0)}>Home</button></div>
    {exercise === 0 && <EX0 setExercise={setExercise} setLevel={setLevel} setPlace={setPlace} doneexercises={doneexercises} doneplaces={doneplaces} setDoneexercises={setDoneexercises} setDoneplaces={setDoneplaces}/>}
    {exercise === 1 && <EX1 place={place} doneexercises={doneexercises} setDoneexercises={setDoneexercises} doneplaces={doneplaces} setDoneplaces={setDoneplaces}/>}
    {exercise === 2 && <EX2 place={place} level={level} doneexercises={doneexercises} setDoneexercises={setDoneexercises} doneplaces={doneplaces} setDoneplaces={setDoneplaces}/>}
    {exercise === 3 && <EX3 place={place} level={level} doneexercises={doneexercises} setDoneexercises={setDoneexercises} doneplaces={doneplaces} setDoneplaces={setDoneplaces}/>}
    {exercise === 4 && <EX4 place={place} level={level} doneexercises={doneexercises} setDoneexercises={setDoneexercises} doneplaces={doneplaces} setDoneplaces={setDoneplaces}/>}
    {exercise === 5 && <EX5 place={place} level={level} doneexercises={doneexercises} setDoneexercises={setDoneexercises} doneplaces={doneplaces} setDoneplaces={setDoneplaces}/>}
    {exercise === 6 && <EX6 place={place} level={level} doneexercises={doneexercises} setDoneexercises={setDoneexercises} doneplaces={doneplaces} setDoneplaces={setDoneplaces}/>}
    {exercise === 7 && <EX7 place={place} doneexercises={doneexercises} setDoneexercises={setDoneexercises} doneplaces={doneplaces} setDoneplaces={setDoneplaces}/>}
    {exercise === 8 && <EX8 place={place} level={level} doneexercises={doneexercises} setDoneexercises={setDoneexercises} doneplaces={doneplaces} setDoneplaces={setDoneplaces}/>}
    {exercise === 9 && <EX9 place={place} doneexercises={doneexercises} setDoneexercises={setDoneexercises} doneplaces={doneplaces} setDoneplaces={setDoneplaces}/>}
    {exercise === 10 && <Credits/>}
    </div>
  </>
  )
}
export default App;
