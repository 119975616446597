import React, { useState }  from 'react';
import '../App.css';
import { useTranslation } from 'react-i18next';
import Graph from "react-graph-vis";


//make ai do icons for translations!!!!! also the reis backen thing will not work
const graphGross1 = {
  nodes: [
    { id: 1, label: "Sauce wuerzen", color: "#e04141" },
    { id: 2, label: "Getraenk bereitstellen", color: "#e09c41" },
    { id: 3, label: "Tisch decken", color: "#e0df41" },
    { id: 4, label: "Auftischen", color: "#7be041" },
    { id: 5, label: "Sauce hizugeben", color: "#41e0c9" },
    { id: 6, label: "Gemuese hizugeben", color: "#7be041" },
    { id: 7, label: "Fleisch braten", color: "#e09c41" },
    { id: 8, label: "Reis waschen", color: "#41e0c9" },
    { id: 9, label: "Gemuese schneiden", color: "#41e0c9" },
    { id: 10, label: "Reis kochen", color: "#41e0c9" }
  ],
  edges: [
    { from: 6, to: 9 },
    { from: 8, to: 10 },
    { from: 5, to: 7 },
    { from: 5, to: 6 },
    { from: 1, to: 5 },
    { from: 3, to: 4 },
    { from: 6, to: 4 },
    { from: 10, to: 4 },
    { from: 3, to: 2 },
  ]
};
const graphGross2 = {
  nodes: [
    { id: 1, label: "Sauce wuerzen", color: "#e04141" },
    { id: 2, label: "Getraenk bereitstellen", color: "#e09c41" },
    { id: 3, label: "Tisch decken", color: "#e0df41" },
    { id: 4, label: "Auftischen", color: "#7be041" },
    { id: 5, label: "Sauce hizugeben", color: "#41e0c9" },
    { id: 6, label: "Gemuese hizugeben", color: "#7be041" },
    { id: 7, label: "Fleisch braten", color: "#e09c41" },
    { id: 8, label: "Reis waschen", color: "#41e0c9" },
    { id: 9, label: "Gemuese schneiden", color: "#41e0c9" },
    { id: 10, label: "Reis kochen", color: "#41e0c9" }
  ],
  edges: [
    { from: 9, to: 6 },
    { from: 8, to: 10 },
    { from: 7, to: 5 },
    { from: 5, to: 6 },
    { from: 1, to: 5 },
    { from: 3, to: 4 },
    { from: 6, to: 4 },
    { from: 10, to: 4 },
    { from: 3, to: 2 },
  ]
};
const graphGross3 = {
  nodes: [
    { id: 1, label: "Sauce wuerzen", color: "#e04141" },
    { id: 2, label: "Getraenk bereitstellen", color: "#e09c41" },
    { id: 3, label: "Tisch decken", color: "#e0df41" },
    { id: 4, label: "Auftischen", color: "#7be041" },
    { id: 5, label: "Sauce hizugeben", color: "#41e0c9" },
    { id: 6, label: "Gemuese hizugeben", color: "#7be041" },
    { id: 7, label: "Fleisch braten", color: "#e09c41" },
    { id: 8, label: "Reis waschen", color: "#41e0c9" },
    { id: 9, label: "Gemuese schneiden", color: "#41e0c9" },
    { id: 10, label: "Reis backen", color: "#41e0c9" }
  ],
  edges: [
    { from: 9, to: 6 },
    { from: 8, to: 10 },
    { from: 7, to: 5 },
    { from: 5, to: 6 },
    { from: 1, to: 5 },
    { from: 3, to: 4 },
    { from: 6, to: 4 },
    { from: 10, to: 4 },
    { from: 3, to: 2 },
  ]
};
const graphKlein2 = {
  nodes: [
    { id: 1, label: "Sauce wuerzen", color: "#e04141" },
    { id: 4, label: "Auftischen", color: "#7be041" },
    { id: 5, label: "Sauce hizugeben", color: "#41e0c9" },
    { id: 6, label: "Gemuese hizugeben", color: "#7be041" },
    { id: 7, label: "Fleisch braten", color: "#e09c41" },
    { id: 8, label: "Reis waschen", color: "#41e0c9" },
    { id: 9, label: "Gemuese schneiden", color: "#41e0c9" },
    { id: 10, label: "Reis kochen", color: "#41e0c9" }
  ],
  edges: [
    { from: 9, to: 6 },
    { from: 8, to: 10 },
    { from: 7, to: 5 },
    { from: 5, to: 6 },
    { from: 1, to: 5 },
    { from: 6, to: 4 },
    { from: 10, to: 4 },
  ]
};
const graphKlein3 = {
  nodes: [
    { id: 1, label: "Sauce wuerzen", color: "#e04141" },
    { id: 4, label: "Auftischen", color: "#7be041" },
    { id: 5, label: "Sauce hizugeben", color: "#41e0c9" },
    { id: 6, label: "Gemuese hizugeben", color: "#7be041" },
    { id: 7, label: "Fleisch braten", color: "#e09c41" },
    { id: 8, label: "Reis waschen", color: "#41e0c9" },
    { id: 9, label: "Gemuese schneiden", color: "#41e0c9" },
    { id: 10, label: "Reis backen", color: "#41e0c9" }
  ],
  edges: [
    { from: 9, to: 6 },
    { from: 8, to: 10 },
    { from: 7, to: 5 },
    { from: 5, to: 6 },
    { from: 1, to: 5 },
    { from: 6, to: 4 },
    { from: 10, to: 4 },
  ]
};
const graphMittel1 = {
  nodes: [
    { id: 1, label: "Sauce wuerzen", color: "#e04141" },
    { id: 3, label: "Tisch decken", color: "#e0df41" },
    { id: 4, label: "Auftischen", color: "#7be041" },
    { id: 5, label: "Sauce hizugeben", color: "#41e0c9" },
    { id: 6, label: "Gemuese hizugeben", color: "#7be041" },
    { id: 7, label: "Fleisch braten", color: "#e09c41" },
    { id: 8, label: "Reis waschen", color: "#41e0c9" },
    { id: 9, label: "Gemuese schneiden", color: "#41e0c9" },
    { id: 10, label: "Reis kochen", color: "#41e0c9" }
  ],
  edges: [
    { from: 6, to: 9 },
    { from: 8, to: 10 },
    { from: 5, to: 7 },
    { from: 5, to: 6 },
    { from: 1, to: 5 },
    { from: 3, to: 4 },
    { from: 6, to: 4 },
    { from: 10, to: 4 },
  ]
}
  const graphMittel2 = {
    nodes: [
      { id: 1, label: "Sauce wuerzen", color: "#e04141" },
      { id: 3, label: "Tisch decken", color: "#e0df41" },
      { id: 4, label: "Auftischen", color: "#7be041" },
      { id: 5, label: "Sauce hizugeben", color: "#41e0c9" },
      { id: 6, label: "Gemuese hizugeben", color: "#7be041" },
      { id: 7, label: "Fleisch braten", color: "#e09c41" },
      { id: 8, label: "Reis waschen", color: "#41e0c9" },
      { id: 9, label: "Gemuese schneiden", color: "#41e0c9" },
      { id: 10, label: "Reis kochen", color: "#41e0c9" }
    ],
    edges: [
      { from: 9, to: 6 },
      { from: 8, to: 10 },
      { from: 7, to: 5 },
      { from: 5, to: 6 },
      { from: 1, to: 5 },
      { from: 3, to: 4 },
      { from: 6, to: 4 },
      { from: 10, to: 4 },
    ]
  }
    const graphMittel3 = {
      nodes: [
        { id: 1, label: "Sauce wuerzen", color: "#e04141" },
        { id: 3, label: "Tisch decken", color: "#e0df41" },
        { id: 4, label: "Auftischen", color: "#7be041" },
        { id: 5, label: "Sauce hizugeben", color: "#41e0c9" },
        { id: 6, label: "Gemuese hizugeben", color: "#7be041" },
        { id: 7, label: "Fleisch braten", color: "#e09c41" },
        { id: 8, label: "Reis waschen", color: "#41e0c9" },
        { id: 9, label: "Gemuese schneiden", color: "#41e0c9" },
        { id: 10, label: "Reis backen", color: "#41e0c9" }
      ],
      edges: [
        { from: 9, to: 6 },
        { from: 8, to: 10 },
        { from: 7, to: 5 },
        { from: 5, to: 6 },
        { from: 1, to: 5 },
        { from: 3, to: 4 },
        { from: 6, to: 4 },
        { from: 10, to: 4 },
      ]
};
const EX2 = ({place, level, doneexercises, setDoneexercises, doneplaces, setDoneplaces}) => {
  const { t } = useTranslation();
  const [antwort, setAntwort] = useState(0);


  const Tabeletry = () => {
    return (
     <table style={{width: '200px', height: '200px'}}>
      <tr>
        <td></td>
        <td>{t('saucew')}</td>
        <td>{t('auftischen')}</td>
        <td>{t('sauceh')}</td>
        <td>{t('gemueseh')}</td>
        <td>{t('fleischb')}</td>
        <td>{t('reisw')}</td>
        <td>{t('gemueses')}</td>
        <td style={{color: antwort===3 && 'red', fontWeight: antwort===3 && 'bold'}}>{t('reisk')}</td>
        {level >= 1 && <td>{t('tisch')}</td>}
        {level === 2 && <td>{t('getraenk')}</td>}
      </tr>
      <tr>
        <td>{t('saucew')}</td>
        <td></td>
        <td></td>
        <td>x</td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        {level >= 1 && <td></td>}
        {level === 2 && <td></td>}
      </tr>
      <tr>
        <td>{t('auftischen')}</td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        {level >= 1 && <td></td>}
        {level === 2 && <td></td>}
      </tr>
      <tr>
        <td>{t('sauceh')}</td>
        <td></td>
        <td></td>
        <td></td>
        <td>x</td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        {level >= 1 && <td></td>}
        {level === 2 && <td></td>}
      </tr>
      <tr>
        <td>{t('gemueseh')}</td>
        <td></td>
        <td>x</td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        {level >= 1 && <td></td>}
        {level === 2 && <td></td>}
      </tr>
      <tr>
        <td>{t('fleischb')}</td>
        <td></td>
        <td></td>
        <td style={{color: antwort===1 && 'red', fontWeight: antwort===1 && 'bold'}}>x</td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        {level >= 1 && <td></td>}
        {level === 2 && <td></td>}
      </tr>
      <tr>
        <td>{t('reisw')}</td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td>x</td>
        {level >= 1 && <td></td>}
        {level === 2 && <td></td>}
      </tr>
      <tr>
        <td>{t('gemueses')}</td>
        <td></td>
        <td></td>
        <td></td>
        <td style={{color: antwort===1 && 'red', fontWeight: antwort===1 && 'bold'}}>x</td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        {level >= 1 && <td></td>}
        {level === 2 && <td></td>}
      </tr>
      <tr>
        <td style={{color: antwort===3 && 'red', fontWeight: antwort===3 && 'bold'}}>{t('reisk')}</td>
        <td></td>
        <td>x</td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        {level >= 1 && <td></td>}
        {level === 2 && <td></td>}
      </tr>
      {level>=1 &&<tr>
        <td>{t('tisch')}</td>
        <td></td>
        <td>x</td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        {level === 2 && <td>x</td>}
        </tr>}
      {level===2 &&<tr>
        <td>{t('getraenk')}</td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        </tr>}
      </table>
      );
  }
  const TauschhandelMittel1 = () => {
    const options = {
      layout: {
        hierarchical: false
      },
      edges: {
        color: "#000000"
      }
    };
    return (
      <div>
        <Graph graph={graphMittel1} options={options} style={{ height: "500px", width:"500px", backgroundColor:"lightgray", borderRadius:"20px", borderColor:"black", borderStyle:"solid"  }} />
      </div>
      );
    }
  const TauschhandelGross1 = () => {
    const options = {
      layout: {
        hierarchical: false
      },
      edges: {
        color: "#000000"
      }
    };
    return (
      <div>
        <Graph graph={graphGross1} options={options} style={{ height: "500px", width:"500px", backgroundColor:"lightgray", borderRadius:"20px", borderColor:"black", borderStyle:"solid"  }} />
      </div>
      );
    }
    const TauschhandelKlein1 = () => {
      const options = {
        layout: {
          hierarchical: false
        },
        edges: {
          color: "#000000"
        }
      };
      const graphKlein1 = {
        nodes: [
          { id: 1, label: "Sauce wuerzen", color: "#e04141" },
          { id: 4, label: "Auftischen", color: "#7be041" },
          { id: 5, label: "Sauce hizugeben", color: "#41e0c9" },
          { id: 6, label: "Gemuese hizugeben", color: "#7be041" },
          { id: 7, label: "Fleisch braten", color: "#e09c41" },
          { id: 8, label: "Reis waschen", color: "#41e0c9" },
          { id: 9, label: "Gemuese schneiden", color: "#41e0c9" },
          { id: 10, label: "Reis kochen", color: "#41e0c9" }
        ],
        edges: [
          { from: 6, to: 9 },
          { from: 8, to: 10 },
          { from: 5, to: 7 },
          { from: 5, to: 6 },
          { from: 1, to: 5 },
          { from: 6, to: 4 },
          { from: 10, to: 4 },
        ]
      };
      return (
        <div>
          <Graph graph={graphKlein1} options={options} style={{ height: "500px", width:"500px", backgroundColor:"lightgray", borderRadius:"20px", borderColor:"black", borderStyle:"solid"  }} />
        </div>
        );
      }
      const TauschhandelMittel2 = () => {
        const options = {
          layout: {
            hierarchical: false
          },
          edges: {
            color: "#000000"
          }
        };
        return (
          <div>
            <Graph graph={graphMittel2} options={options} style={{ height: "500px", width:"500px", backgroundColor:"lightgray", borderRadius:"20px", borderColor:"black", borderStyle:"solid"  }} />
          </div>
          );
        }
      const TauschhandelGross2 = () => {
        const options = {
          layout: {
            hierarchical: false
          },
          edges: {
            color: "#000000"
          }
        };
        return (
          <div>
            <Graph graph={graphGross2} options={options} style={{ height: "500px", width:"500px", backgroundColor:"lightgray", borderRadius:"20px", borderColor:"black", borderStyle:"solid"  }} />
          </div>
          );
        }
        const TauschhandelKlein2 = () => {
          const options = {
            layout: {
              hierarchical: false
            },
            edges: {
              color: "#000000"
            }
          };
          return (
            <div>
              <Graph graph={graphKlein2} options={options} style={{ height: "500px", width:"500px", backgroundColor:"lightgray", borderRadius:"20px", borderColor:"black", borderStyle:"solid"  }} />
            </div>
            );
          }
          const TauschhandelMittel3 = () => {
            const options = {
              layout: {
                hierarchical: false
              },
              edges: {
                color: "#000000"
              }
            };
            return (
              <div>
                <Graph graph={graphMittel3} options={options} style={{ height: "500px", width:"500px", backgroundColor:"lightgray", borderRadius:"20px", borderColor:"black", borderStyle:"solid"  }} />
              </div>
              );
            }
          const TauschhandelGross3 = () => {
            const options = {
              layout: {
                hierarchical: false
              },
              edges: {
                color: "#000000"
              }
            };
            return (
              <div>
                <Graph graph={graphGross3} options={options} style={{ height: "500px", width:"500px", backgroundColor:"lightgray", borderRadius:"20px", borderColor:"black", borderStyle:"solid"  }} />
              </div>
              );
            }
            const TauschhandelKlein3 = () => {
              const options = {
                layout: {
                  hierarchical: false
                },
                edges: {
                  color: "#000000"
                }
              };
              return (
                <div>
                  <Graph graph={graphKlein3} options={options} style={{ height: "500px", width:"500px", backgroundColor:"lightgray", borderRadius:"20px", borderColor:"black", borderStyle:"solid"  }} />
                </div>
                );
              }
  
  
  function correct(){
    setAntwort(4);
    let prevex = doneexercises;
    prevex[1]= true;
    setDoneexercises(prevex);
    let prevplace = doneplaces;
    prevplace[place]= true;
    setDoneplaces(prevplace);
    window.alert("Good Job!");
  }
  return (
    <div className='exercises'>
      <p>{t('intrEX2')}</p>
      <div style={{display: 'flex', alignItems:'center',flexDirection:'row', justifyContent:'center'}}> 
        <Tabeletry/>
     </div>
        
        <p>{t('fragEX2')}</p>
      

        <div style={{display: 'flex', alignItems:'center',flexDirection:'row', justifyContent:'center'}}>
          <div style={{display: 'flex', flexDirection:'column', alignItems:'center',justifyContent:'center'}}>
          {level ===0 && <TauschhandelKlein1/>}
          {level ===1 && <TauschhandelMittel1/>}
          {level ===2 && <TauschhandelGross1/>}
          <label ><input type="radio" checked={antwort === 1} onClick={()=>setAntwort(1)}/>{t('graph')} 1</label>
          </div>
          <div style={{display: 'flex', flexDirection:'column', alignItems:'center', justifyContent:'center'}}>
            {level ===0 && <TauschhandelKlein2/>}
            {level ===1 && <TauschhandelMittel2/>}
            {level ===2 && <TauschhandelGross2/>}
          <label ><input type="radio" checked={antwort === 4} onClick={()=>correct()}/>{t('graph')} 2</label>
          </div>
          <div style={{display: 'flex', flexDirection:'column', alignItems:'center', justifyContent:'center'}}>
          {level ===0 && <TauschhandelKlein3/>}
          {level ===1 && <TauschhandelMittel3/>}
          {level ===2 && <TauschhandelGross3/>}
          <label ><input type="radio" checked={antwort === 3} onClick={()=>setAntwort(3)}/>{t('graph')} 3</label>

          </div>
        </div>
      <div style={{height:"100px"}}></div>
    </div>
  );
}

export default EX2;
