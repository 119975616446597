import React, { useState }  from 'react';
import '../App.css';
import { useTranslation } from 'react-i18next';

const EX8 = ({place, level, doneexercises, setDoneexercises, doneplaces, setDoneplaces}) => {
  const { t } = useTranslation();
  const [antwort, setAntwort] = useState(0);

  const Tabeletry1 = () => {
    return (
     <table style={{width: '550px', height: '100px'}}>
      <tr>
        <td></td>
        <td>Alanui</td>
        <td>Mele</td>
        <td>Oukou</td>
        <td>Aina</td>
        <td>Lawe</td>
        {level >= 1 && <td>Laau</td>}
        {level >= 1 && <td>Nui</td>}
        {level === 2 && <td>Maoli</td>}
      </tr>
      <tr>
        <td>Alanui</td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        {level >= 1 && <td></td>}
        {level >= 1 && <td></td>}
        {level >= 2 && <td></td>}
      </tr>
      <tr>
        <td>Mele</td>
        <td>x</td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        {level >= 1 && <td>x</td>}
        {level >= 1 && <td></td>}
        {level >= 2 && <td></td>}
      </tr>
      <tr>
        <td>Oukou</td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        {level >= 1 && <td></td>}
        {level >= 1 && <td>x</td>}
        {level >= 2 && <td></td>}
      </tr>
      <tr>
        <td>Aina</td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        {level >= 1 && <td></td>}
        {level >= 1 && <td></td>}
        {level >= 2 && <td>x</td>}
      </tr>
      <tr>
        <td>Lawe</td>
        <td></td>
        <td>x</td>
        <td></td>
        <td>x</td>
        <td></td>
        {level >= 1 && <td></td>}
        {level >= 1 && <td></td>}
        {level >= 2 && <td></td>}
      </tr>
      {level>=1 &&<tr>
          <td>Laau</td>
          <td></td>
          <td></td>
          <td>x</td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          {level === 2 && <td></td>}
        </tr>}
        {level>=1 &&<tr>
          <td>Nui</td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          {level === 2 && <td></td>}
        </tr>}
      {level===2 &&<tr>
        <td>Maoli</td>
          <td></td>
          <td>x</td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
        </tr>}
        </table>
      );
  }
  const Tabeletry2 = () => {
    return (
     <table style={{width: '550px', height: '100px'}}>
      <tr>
        <td></td>
        <td>Alanui</td>
        <td>Mele</td>
        <td>Oukou</td>
        <td>Aina</td>
        <td>Lawe</td>
        {level >= 1 && <td>Laau</td>}
        {level >= 1 && <td>Nui</td>}
        {level === 2 && <td>Maoli</td>}
      </tr>
      <tr>
        <td>Alanui</td>
        <td></td>
        <td>x</td>
        <td></td>
        <td></td>
        <td></td>
        {level >= 1 && <td></td>}
        {level >= 1 && <td></td>}
        {level >= 2 && <td></td>}
      </tr>
      <tr>
        <td>Mele</td>
        <td></td>
        <td></td>
        <td>x</td>
        <td></td>
        <td>x</td>
        {level >= 1 && <td>x</td>}
        {level >= 1 && <td></td>}
        {level >= 2 && <td></td>}
      </tr>
      <tr>
        <td>Oukou</td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        {level >= 1 && <td></td>}
        {level >= 1 && <td>x</td>}
        {level >= 2 && <td></td>}
      </tr>
      <tr>
        <td>Aina</td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        {level >= 1 && <td></td>}
        {level >= 1 && <td></td>}
        {level >= 2 && <td>x</td>}
      </tr>
      <tr>
        <td>Lawe</td>
        <td></td>
        <td></td>
        <td></td>
        <td>x</td>
        <td></td>
        {level >= 1 && <td></td>}
        {level >= 1 && <td></td>}
        {level >= 2 && <td></td>}
      </tr>
      {level>=1 &&<tr>
          <td>Laau</td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td>x</td>
          <td></td>
          <td></td>
          {level === 2 && <td></td>}
        </tr>}
        {level>=1 &&<tr>
          <td>Nui</td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          {level === 2 && <td></td>}
        </tr>}
      {level===2 &&<tr>
        <td>Maoli</td>
          <td>x</td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
        </tr>}
        </table>
      );
  }
  const Tabeletry3 = () => {
    return (
     <table style={{width: '550px', height: '100px'}}>
      <tr>
        <td></td>
        <td>Alanui</td>
        <td>Mele</td>
        <td>Oukou</td>
        <td>Aina</td>
        <td>Lawe</td>
        {level >= 1 && <td>Laau</td>}
        {level >= 1 && <td>Nui</td>}
        {level === 2 && <td>Maoli</td>}
      </tr>
      <tr>
        <td>Alanui</td>
        <td></td>
        <td>x</td>
        <td></td>
        <td></td>
        <td></td>
        {level >= 1 && <td></td>}
        {level >= 1 && <td></td>}
        {level >= 2 && <td></td>}
      </tr>
      <tr>
        <td>Mele</td>
        <td></td>
        <td></td>
        <td>x</td>
        <td></td>
        <td></td>
        {level >= 1 && <td>x</td>}
        {level >= 1 && <td></td>}
        {level >= 2 && <td></td>}
      </tr>
      <tr>
        <td>Oukou</td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        {level >= 1 && <td></td>}
        {level >= 1 && <td>x</td>}
        {level >= 2 && <td></td>}
      </tr>
      <tr>
        <td>Aina</td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        {level >= 1 && <td></td>}
        {level >= 1 && <td></td>}
        {level >= 2 && <td>x</td>}
      </tr>
      <tr>
        <td>Lawe</td>
        <td></td>
        <td>x</td>
        <td></td>
        <td>x</td>
        <td></td>
        {level >= 1 && <td></td>}
        {level >= 1 && <td></td>}
        {level >= 2 && <td></td>}
      </tr>
      {level>=1 &&<tr>
          <td>Laau</td>
          <td></td>
          <td></td>
          <td>x</td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          {level === 2 && <td></td>}
        </tr>}
        {level>=1 &&<tr>
          <td>Nui</td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          {level === 2 && <td></td>}
        </tr>}
      {level===2 &&<tr>
        <td>Maoli</td>
          <td></td>
          <td>x</td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
        </tr>}
        </table>
      );
  }
  const Ruletry2 = () => {
    return (
      <div>
        <div> 
        <ol>
        <li style={{color: antwort===1 && 'red', fontWeight: antwort===1 && 'bold'}}>{t('r1EX8')}</li>
        <li style={{color: antwort===2 && 'red', fontWeight: antwort===2 && 'bold'}}>{t('r6EX8')}</li>
        <li>{t('r4EX8')}</li>
        <li>{t('r7EX8')}</li>
        {level>=1 &&<li>{t('r5EX8')}</li>}
        {level>=1 &&<li style={{color: antwort===2 && 'red', fontWeight: antwort===2 && 'bold'}}>{t('r3EX8')}</li>}
        {level>=1 &&<li style={{color: antwort===1 && 'red', fontWeight: antwort===1 && 'bold'}}>{t('r2EX8')}</li>}
        {level===2 &&<li>{t('r8EX8')}</li>}
        {level===2 &&<li style={{color: antwort===2 && 'red', fontWeight: antwort===2 && 'bold'}}>{t('r9EX8')}</li>}
        </ol>
      </div>
      </div>
    );
  }
  function correct(){
    setAntwort(3);
    let prevex = doneexercises;
    prevex[7]= true;
    setDoneexercises(prevex);
    let prevplace = doneplaces;
    prevplace[place]= true;
    setDoneplaces(prevplace);
    window.alert("Good Job!");
  }
  return (
    <div className='exercises'>
      <p>{t('intrEX8')}</p>
      <Ruletry2/>
      <p>{t('fragEX8')}</p>
      <div style={{display: 'flex', alignItems:'center',flexDirection:'row', justifyContent:'center'}}>
          <div style={{display: 'flex', flexDirection:'column', alignItems:'center',justifyContent:'center'}}>
          <Tabeletry1/>
          <p><label ><input type="radio" checked={antwort === 1} onClick={()=>setAntwort(1)}/>{t('table')} 1</label></p>
          </div>
          <div style={{display: 'flex', flexDirection:'column', alignItems:'center',justifyContent:'center'}}>
          <Tabeletry2/>
          <p><label ><input type="radio" checked={antwort === 2} onClick={()=>setAntwort(2)}/>{t('table')} 2</label></p>
          </div>
          <div style={{display: 'flex', flexDirection:'column', alignItems:'center',justifyContent:'center'}}>
          <Tabeletry3/>  
          <p><label ><input type="radio" checked={antwort === 3} onClick={correct}/>{t('table')} 3</label></p>
          </div>
      </div>
    </div>
  );
}

export default EX8;
