import React, { useState } from 'react';
import '../App.css';
import Graph from "react-graph-vis";
import { useTranslation } from 'react-i18next';

const graph = {
  nodes: [
    { id: 1, color: "#e04141" , shape:"image", image:"assets/pics/unterhose.png"},
    { id: 2, color: "#e09c41", shape:"image", image:"assets/pics/sockeL.png" },
    { id: 3, color: "#e0df41", shape:"image", image:"assets/pics/kappe.png" },
    { id: 4, color: "#7be041", shape:"image", image:"assets/pics/jacke.png" },
    { id: 5, color: "#41e0c9", shape:"image", image:"assets/pics/hose.png" },
    { id: 6, color: "#7be041", shape:"image", image:"assets/pics/shirt.png" },
    { id: 7, color: "#e09c41", shape:"image", image:"assets/pics/unterleibchen.png" },
    { id: 8, color: "#41e0c9", shape:"image", image:"assets/pics/schuhL.png" }
  ],
  edges: [
    { from: 1, to: 5 },
    { from: 7, to: 6 },
    { from: 2, to: 8 },
    { from: 5, to: 8 },
    { from: 6, to: 4 },
    { from: 5, to: 4 }
  ]
};

const EX1 = ({place, doneexercises, setDoneexercises, doneplaces, setDoneplaces}) => {  
  const [darstellung, setDarstellung] = useState(0);
  const [antwort, setAntwort] = useState(2);
  const { t } = useTranslation();

  const Tabeletry = () => {
    return (
     <table style={{width: '800px', height: '200px'}}>
      <tr>
        <td></td>
        <td>{t('shoes')}</td>
        <td>{t('undershirt')}</td>
        <td>{t('trousers')}</td>
        <td>{t('jacket')}</td>
        <td>{t('socks')}</td>
        <td>{t('cap')}</td>
        <td>{t('underpants')}</td>
        <td>{t('shirt')}</td>
      </tr>
      <tr>
        <td>{t('shoes')}</td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
      </tr>
      <tr>
        <td>{t('undershirt')}</td>
        <td></td>
        <td></td>
        <td style={{color: antwort===1 && 'red', fontWeight: antwort===1 && 'bold'}}>x</td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td>x</td>
      </tr>
      <tr>
        <td>{t('trousers')}</td>
        <td style={{color: antwort===3 && 'red', fontWeight: antwort===3 && 'bold'}}>x</td>
        <td></td>
        <td></td>
        <td style={{color: antwort===5 && 'red', fontWeight: antwort===5 && 'bold'}}>x</td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
      </tr>
      <tr>
        <td>{t('jacket')}</td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
      </tr>
      <tr>
        <td>{t('socks')}</td>
        <td>x</td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
      </tr>
      <tr>
        <td>{t('cap')}</td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
      </tr>
      <tr>
        <td>{t('underpants')}</td>
        <td></td>
        <td></td>
        <td style={{color: antwort===0 && 'red', fontWeight: antwort===0 && 'bold'}}>x</td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
      </tr>
      <tr>
        <td>{t('shirt')}</td>
        <td></td>
        <td></td>
        <td></td>
        <td>x</td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
      </tr>
      </table>
      );

  }


  const Ruletry2 = () => {

    return (
      <div>
        <ol>
        <li style={{color: antwort===1 && 'red', fontWeight: antwort===1 && 'bold'}}>{t('r1EX1')}</li>
        <li>{t('r2EX1')}</li>
        <li style={{color: antwort===0 && 'red', fontWeight: antwort===0 && 'bold'}}>{t('r3EX1')}</li>
        <li style={{color: antwort===3 && 'red', fontWeight: antwort===3 && 'bold'}}>{t('r4EX1')}</li>
        <li>{t('r5EX1')}</li>
        <li style={{color: antwort===5 && 'red', fontWeight: antwort===5 && 'bold'}}>{t('r6EX1')}</li>
        </ol>
      </div>
    );
  }
  const OverwieFlow0 = () => {
    const options = {
      layout: {
        hierarchical: false
      },
      edges: {
        color: "#000000"
      }
    };

    return (
      <div style={{display: 'flex', alignItems:'center', justifyContent:'center'}}>
        <Graph graph={graph} options={options} style={{ height: "500px", width:"500px", backgroundColor:"lightgray", borderRadius:"20px", borderColor:"black", borderStyle:"solid"  }} />
      </div>
    );
  }
  function correct(){
    graph.edges[3].color="black";
    graph.edges[0].color="black";
    graph.edges[1].color="black";
    graph.edges[5].color="black";
    graph.edges[5].value=undefined;
    graph.edges[0].value=undefined;
    graph.edges[1].value=undefined;
    graph.edges[3].value=undefined;
    setAntwort(4);
    let prevex = doneexercises;
    prevex[0]= true;
    setDoneexercises(prevex);
    let prevplace = doneplaces;
    prevplace[place]= true;
    setDoneplaces(prevplace);
    window.alert("Good Job!");
  }
  function wrong(enr){
    graph.edges[3].color="black";
    graph.edges[0].color="black";
    graph.edges[1].color="black";
    graph.edges[5].color="black";
    graph.edges[5].value=undefined;
    graph.edges[0].value=undefined;
    graph.edges[1].value=undefined;
    graph.edges[3].value=undefined;
    setAntwort(enr);
    graph.edges[enr].color="red";
    graph.edges[enr].value=1;
  }
  
  return (
    <div className='exercises'>
        <p>{t('intrEX1')}</p>
        <div style={{display: 'flex', alignItems:'center',flexDirection:'row', justifyContent:'center'}}>
          {darstellung === 0 && <Ruletry2/>}
          {darstellung === 1  && <OverwieFlow0/>}
          {darstellung === 2  && <Tabeletry/>}
        </div>
        <button className= "pretButton" onClick={()=>setDarstellung(0)}>{t('text')}</button>
        <button className= "pretButton" onClick={()=>setDarstellung(1)}>{t('graph')}</button>
        <button className= "pretButton" onClick={()=>setDarstellung(2)}>{t('table')}</button>
        <p>{t('fragEX1')}</p>
        <ol className="antworten">
        <li><label ><input type="radio" checked={antwort === 5} onClick={()=>wrong(5)}/>{t('a1EX1')}</label></li>
        <li><label ><input type="radio" checked={antwort === 3} onClick={()=>wrong(3)}/>{t('a2EX1')}</label></li>
        <li><label ><input type="radio" checked={antwort === 0} onClick={()=>wrong(0)}/>{t('a3EX1')}</label></li>
        <li><label ><input type="radio" checked={antwort === 1} onClick={()=>wrong(1)}/>{t('a4EX1')}</label></li>
        <li><label ><input type="radio" checked={antwort === 4} onClick={correct}/>{t('a5EX1')}</label></li>
        </ol>
  </div>
  )
}
     
export default EX1;