import React, { useState }  from 'react';
import '../App.css';
import { useTranslation } from 'react-i18next';


const EX4 = ({place, level, doneexercises, setDoneexercises, doneplaces, setDoneplaces}) => {
  const { t } = useTranslation();
  const [antwort, setAntwort] = useState(0);

    const Tabeletry = () => {
      return (
       <table style={{width: '800px', height: '200px'}}>
        <tr>
          <td></td>
          <td>{t('frosch')}</td>
          <td>{t('storch')}</td>
          <td>{t('schildkroete')}</td>
          <td>{t('schlange')}</td>
          <td>{t('fisch')}</td>
          <td>{t('kroko')}</td>
          {level >= 1 && <td>{t('otter')}</td>}
          {level >= 1 && <td>{t('tintenfisch')}</td>}
          {level === 2 && <td>{t('ente')}</td>}
        </tr>
        <tr>
          <td>{t('frosch')}</td>
          <td></td>
          <td  style={{color: antwort===2 && 'red', fontWeight: antwort===2 && 'bold'}}>x</td>
          <td>x</td>
          <td></td>
          <td></td>
          <td></td>
          {level >= 1 && <td></td>}
          {level >= 1 && <td></td>}
          {level >= 2 && <td></td>}
        </tr>
        <tr>
          <td>{t('storch')}</td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          {level >= 1 && <td>x</td>}
          {level >= 1 && <td></td>}
          {level >= 2 && <td></td>}
        </tr>
        <tr>
          <td>{t('schildkroete')}</td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          {level >= 1 && <td></td>}
          {level >= 1 && <td></td>}
          {level >= 2 && <td  style={{color: antwort===2 && 'red', fontWeight: antwort===2 && 'bold'}}>x</td>}
        </tr>
        <tr>
          <td>{t('schlange')}</td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          {level >= 1 && <td></td>}
          {level >= 1 && <td></td>}
          {level >= 2 && <td></td>}
        </tr>
        <tr>
          <td>{t('fisch')}</td>
          <td  style={{color: antwort===3 && 'red', fontWeight: antwort===3 && 'bold'}}>x</td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          {level >= 1 && <td></td>}
          {level >= 1 && <td></td>}
          {level >= 2 && <td></td>}
        </tr>
        <tr>
          <td>{t('kroko')}</td>
          <td></td>
          <td>x</td>
          <td></td>
          <td>x</td>
          <td></td>
          <td></td>
          {level >= 1 && <td></td>}
          {level >= 1 && <td></td>}
          {level >= 2 && <td></td>}
          </tr>
          {level>=1 &&<tr>
            <td>{t('otter')}</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            {level === 2 && <td></td>}
          </tr>}
          {level>=1 &&<tr>
            <td>{t('tintenfisch')}</td>
            <td  style={{color: antwort===3 && 'red', fontWeight: antwort===3 && 'bold'}}>x</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            {level === 2 && <td></td>}
          </tr>}
        {level===2 &&<tr>
          <td>{t('ente')}</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td>x</td>
            <td></td>
            <td></td>
            <td></td>
          </tr>}
          </table>
        );
    }

  const Ruletry1 = () => {
    return (
      <div>
        <div> 
        <ol>
        {level>=1 &&<li>{t('r7EX4')}</li>}
        <li>{t('r1EX4')}</li>
        <li>{t('r6EX4')}</li>
        <li>{t('r2EX4')}</li>
        {level===2 && <li>{t('r9EX4')}</li>}
        <li>{t('r4EX4')}</li>
        {level>=1 &&<li>{t('r5EX4')}</li>}
        {level===2 && <li>{t('r10EX4')}</li>}
        <li>{t('r3EX4')}</li>
        </ol>
      </div>
      </div>
    );
  }
  
  const Ruletry2 = () => {
    return (
      <div>
        <div> 
        <ol>
        <li>{t('r1EX4')}</li>
        <li>{t('fr2EX4')}</li>
        {level===2 && <li>{t('fr9EX4')}</li>}
        <li>{t('r3EX4')}</li>
        <li>{t('r4EX4')}</li>
        {level>=1 &&<li>{t('r7EX4')}</li>}
        <li>{t('r6EX4')}</li>
        {level===2 && <li>{t('r10EX4')}</li>}
        {level>=1 &&<li>{t('r5EX4')}</li>}
        </ol>
      </div>
      </div>
    );
  }

  const Ruletry3 = () => {
    return (
      <div>
        <div> 
        <ol>
        <li>{t('r4EX4')}</li>
        <li>{t('r1EX4')}</li>
        {level===2 && <li>{t('r9EX4')}</li>}
        <li>{t('r2EX4')}</li>
        <li>{t('fr6EX4')}</li>
        {level>=1 &&<li>{t('fr7EX4')}</li>}
        <li>{t('r3EX4')}</li>
        {level>=1 &&<li>{t('r5EX4')}</li>}
        {level===2 && <li>{t('r10EX4')}</li>}
        </ol>
      </div>
      </div>
    );
  }
  function correct(){
    setAntwort(1);
    let prevex = doneexercises;
    prevex[3]= true;
    setDoneexercises(prevex);
    let prevplace = doneplaces;
    prevplace[place]= true;
    setDoneplaces(prevplace);
    window.alert("Good Job!");
  }
  return (
    <div className='exercises'>
      <p>{t('intrEX4')}</p>
      <div style={{display: 'flex', flexDirection:'column', alignItems:'center',justifyContent:'center'}}>
        <Tabeletry/>
      </div>
      <p>{t('fragEX4')}</p>

      <div style={{display: 'flex', alignItems:'center',flexDirection:'row', justifyContent:'center'}}>
          <div style={{display: 'flex', flexDirection:'column', alignItems:'center',justifyContent:'center'}}>
          <Ruletry1/>
          <p><label ><input type="radio" checked={antwort === 1} onClick={correct}/>{t('regeln')} 1</label></p>
          </div>
          <div style={{display: 'flex', flexDirection:'column', alignItems:'center',justifyContent:'center'}}>
          <Ruletry2/>
          <p><label ><input type="radio" checked={antwort === 2} onClick={()=>setAntwort(2)}/>{t('regeln')} 2</label></p>
          </div>
          <div style={{display: 'flex', flexDirection:'column', alignItems:'center',justifyContent:'center'}}>
          <Ruletry3/>  
          <p><label ><input type="radio" checked={antwort === 3} onClick={()=>setAntwort(3)}/>{t('regeln')} 3</label></p>
          </div>
      </div>
    </div>
  );
}

export default EX4;