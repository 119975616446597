import React, { useState }  from 'react';
import '../App.css';
import { useTranslation } from 'react-i18next';
import Graph from "react-graph-vis";

const graph = {
  nodes: [
    { id: 1, label: "Ben", color: "#e04141" },
    { id: 2, label: "Ann", color: "#e09c41" },
    { id: 3, label: "Steven", color: "#e0df41" },
    { id: 4, label: "Nathan", color: "#7be041" },
    { id: 5, label: "Mike", color: "#41e0c9" },
    { id: 6, label: "Daniela", color: "#7be041" },
    { id: 7, label: "Angela", color: "#e09c41" },
    { id: 8, label: "Peter", color: "#41e0c9" }
  ],
  edges: [
    { from: 1, to: 2 },
    { from: 1, to: 3 },
    { from: 1, to: 4 },
    { from: 6, to: 5 },
    { from: 3, to: 5 },
    { from: 2, to: 7 },
    { from: 7, to: 6 },
    { from: 5, to: 8 },
    { from: 3, to: 8 }
  ]
};

const EX7 = ({doneexercises, setDoneexercises, doneplaces, setDoneplaces}) => {
  const { t } = useTranslation();
  const [values, setValues] = useState(["","","","","","","",""])
  const options = ["Ben", "Ann", "Steven","Nathan","Mike","Daniela","Angela","Peter"]
  const [correctedges, setCorrectedges] = useState([false,false,false,false,false,false,false,false,false])
  const [added, setAdded] = useState(0);
  const [answered, setAnswered] = useState(0);

  const LandschaftMittel = () => {
    const options = {
      layout: {
        hierarchical: false
      },
      edges: {
        color: "#000000"
      }
    };
  
    return (
      <div style={{display: 'flex', alignItems:'center', justifyContent:'center'}}>
        <Graph graph={graph} options={options} style={{ height: "500px", width:"500px", backgroundColor:"lightgray", borderRadius:"20px", borderColor:"black", borderStyle:"solid"  }} />
      </div>
      );
    }
  
  function correct(){
    let allcorrect = true;
    let goodedge = [false,false,false,false,false,false,false,false,false];
    for(let x = 1; x <= 7; x++){
      for(let z = 0; z < x; z++){
        if(values[x]==="Nathan"){
          if(values[z]==="Ben"){
           goodedge[2] = true;
          }
        }
        if(values[x]==="Ann"){
          if(values[z]==="Ben"){
            goodedge[0] = true;
          }
        }
        if(values[x]==="Steven"){
          if(values[z]==="Ben"){
            goodedge[1] = true;
          }   
        }
        if(values[x]==="Mike"){
          if(values[z]==="Daniela"){
            goodedge[3] = true;
          } 
          if(values[z]==="Steven"){
            goodedge[4] = true;
          }   
        }
        if(values[x]==="Daniela"){
          if(values[z]==="Angela"){
            goodedge[6] = true;
          }
        }
        if(values[x]==="Angela"){
          if(values[z]==="Ann"){
            goodedge[5] = true;
          }
        }
        if(values[x]==="Peter"){
          if(values[z]==="Mike"){
            goodedge[7] = true;
          } 
          if(values[z]==="Steven"){
            goodedge[8] = true;
          }  
        }
      }
    }

    setCorrectedges(goodedge);
    for(let x = 0; x <= 8; x++){
      if(!correctedges[x]){
        graph.edges[x].color="red";
        graph.edges[x].value=1;
        allcorrect = false;
      }
      else{
        graph.edges[x].color="black";
        graph.edges[x].value=undefined;
      }
    } 

    setAnswered(answered+1);
    if(allcorrect){
      let prevex = doneexercises;
      prevex[6]= true;
      setDoneexercises(prevex);
      let prevplace = doneplaces;
      prevplace[8]= true;
      setDoneplaces(prevplace);
      window.alert("Good Job!");
    }
  }

  return (
    <div className='exercises'>
      <p>{t('intrEX7')}</p>

     <LandschaftMittel/>
     <p>{t('fragEX7')}</p>
      <div style={{display: 'flex', flexDirection:'row', alignItems:'center', justifyContent:'center'}}>
           {values.map((v, i) => (
              <div style={{ width: 100, height: 30, borderRadius: "10%", backgroundColor: "green", margin: 2, color:'white' }} key={i}>
                {v}
              </div>
            ))}
          </div>
          <div>
            {options.map((v, i) => (
              <button
                className= "pretButton"
                m="sm"
                variant='outline'
                key={i}
                onClick={() => {
                  if (added < options.length)
                    setValues([...values.slice(0, added), options[i], ...values.slice(added + 1)]);
                  setAdded(a => Math.min(a + 1, options.length))
                }}
              >
                {v}
              </button>
            ))}
          </div>                                           
      <button className= "pretButton" onClick={()=>{setValues([...values.map((v,i)=>( i >= added-1 ? "": v))]); setAdded(a=>Math.max(a-1, 0))}}>{t('clear')}</button>     
      <button className= "pretButton" onClick={()=>correct()}>{t('correct')}</button> 
    </div>
  );
}

export default EX7;
