import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
 
const LangSelector = () => {
  const { i18n } = useTranslation();
  const [selectedLang, setSelectedLang] = useState('en');
 
  const changeLanguage = (event) => {
    setSelectedLang(event.target.value);
    i18n.changeLanguage(event.target.value);
  }
 
  return (
    <div className="langCont" onChange={changeLanguage}>
      <label><input type="radio" value="en" name="language" checked={selectedLang === 'en'} /> <img src="assets/pics/english.png" alt="AltEnglisch"></img></label>
      <label><input type="radio" value="de" name="language" checked={selectedLang === 'de'} /> <img src="assets/pics/deutsch.png" alt="AltDeutsch"></img></label>
      <label><input type="radio" value="it" name="language" checked={selectedLang === 'it'} /> <img src="assets/pics/italian.png" alt="Altitaliano"></img></label>
      <label><input type="radio" value="fr" name="language" checked={selectedLang === 'fr'} /> <img src="assets/pics/franz.png" alt="AltFrancais"></img></label>
    </div>
  )
}
 
export default LangSelector;
