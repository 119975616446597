import '../App.css';
import { useTranslation } from 'react-i18next';
import React from 'react';


const Credits = () => {
  
  return (
    <div className='credits'>
      <p>Exercise 1 was lifted from "Einfach Informatik 5/6 - Lösungen finden" by Juraj Hromkovič and Regula Lacher, Klett, ISBN 978-3-264-84546-4.
      Available under https://www.klett.ch/shop/primarschule/medien-und-informatik-informatik/lehrwerk/einfach-informatik-5-6/band/einfach-informatik-5-6-loesungen-finden/978-3-264-84546-4.
      </p>
      <p>
      The stories behind the other exercises were partly inspired by old exercises from bebras, International Challenge on Informatics and Computational Thinking.
      See https://www.bebras.org/ for more information on bebras and https://www.informatik-biber.ch/aufgabensammlung/ressourcen/ for the old exercises from the swiss chapter of bebras.
      </p>
    </div>
  )
}

export default Credits;