import React,{ useState } from 'react';
import '../App.css';
import { useTranslation } from 'react-i18next';

const EX0 = ({setExercise, setLevel, setPlace, doneexercises, doneplaces, setDoneexercises, setDoneplaces}) => {
  const [code, setCode] = useState( "Type your codeword here.");
  const { t } = useTranslation();

  const handleChange = (event) => {
    setCode(event.target.value)
  }

  function CodeForm() {
    return (
      <form>
        <textarea style={{marginTop: '15px'}} value={code} onChange={handleChange} />
      </form>
    )
  }

  function decode(){
   let numChar = code.length;
   let fstPart = code.slice(0, numChar - 1);
   let secPart = code.slice(numChar - 1);
   let binFstPart = parseInt(fstPart, 10).toString(2);
   decodeSecPart(secPart);
   let newExercises = doneexercises;
   for(let i = 0; i <= Math.min(8, numChar - 1); i++){
      if(binFstPart[i] === '1'){
        newExercises[i] = true;
      }
   }
   setDoneexercises(newExercises);
   setCode("Type your codeword here.");
  }

  function decodeSecPart(cha){
    let newPlaces = doneplaces;
    switch (cha) {
      case 'a':
        newPlaces[0]= true;
      break;
      case 's':
        newPlaces[1]= true;
      break;
      case 'd':
        newPlaces[2]= true;
      break;
      case 'f':
        newPlaces[0]= true;
        newPlaces[1]= true;
      break;
      case 'q':
        newPlaces[0]= true;
        newPlaces[2]= true;
      break;
      case 'w':
        newPlaces[1]= true;
        newPlaces[2]= true;
      break;
      case 'e':
        newPlaces[0]= true;
        newPlaces[1]= true;
        newPlaces[2]= true;
      break;
      case 'r':
        newPlaces[0]= true;
        newPlaces[1]= true;
        newPlaces[2]= true;
        newPlaces[3]= true;
      break;
      case 't':
        newPlaces[0]= true;
        newPlaces[1]= true;
        newPlaces[2]= true;
        newPlaces[4]= true;
      break;
      case 'g':
        newPlaces[0]= true;
        newPlaces[1]= true;
        newPlaces[2]= true;
        newPlaces[5]= true;
      break;
      case 'y':
        newPlaces[0]= true;
        newPlaces[1]= true;
        newPlaces[2]= true;
        newPlaces[3]= true;
        newPlaces[4]= true;
      break;
      case 'h':
        newPlaces[0]= true;
        newPlaces[1]= true;
        newPlaces[2]= true;
        newPlaces[3]= true;
        newPlaces[5]= true;
      break;
      case 'u':
        newPlaces[0]= true;
        newPlaces[1]= true;
        newPlaces[2]= true;
        newPlaces[4]= true;
        newPlaces[5]= true;
      break;
      case 'j':
        newPlaces[0]= true;
        newPlaces[1]= true;
        newPlaces[2]= true;
        newPlaces[3]= true;
        newPlaces[4]= true;
        newPlaces[5]= true;
      break;
      case 'i':
        newPlaces[0]= true;
        newPlaces[1]= true;
        newPlaces[2]= true;
        newPlaces[3]= true;
        newPlaces[4]= true;
        newPlaces[5]= true;
        newPlaces[6]= true;
      break;
      case 'k':
        newPlaces[0]= true;
        newPlaces[1]= true;
        newPlaces[2]= true;
        newPlaces[3]= true;
        newPlaces[4]= true;
        newPlaces[5]= true;
        newPlaces[7]= true;
      break;
      case 'z':
        newPlaces[0]= true;
        newPlaces[1]= true;
        newPlaces[2]= true;
        newPlaces[3]= true;
        newPlaces[4]= true;
        newPlaces[5]= true;
        newPlaces[8]= true;
      break;
      case 'x':
        newPlaces[0]= true;
        newPlaces[1]= true;
        newPlaces[2]= true;
        newPlaces[3]= true;
        newPlaces[4]= true;
        newPlaces[5]= true;
        newPlaces[6]= true;
        newPlaces[7]= true;
      break;
      case 'c':
        newPlaces[0]= true;
        newPlaces[1]= true;
        newPlaces[2]= true;
        newPlaces[3]= true;
        newPlaces[4]= true;
        newPlaces[5]= true;
        newPlaces[6]= true;
        newPlaces[8]= true;
      break;
      case 'v':
        newPlaces[0]= true;
        newPlaces[1]= true;
        newPlaces[2]= true;
        newPlaces[3]= true;
        newPlaces[4]= true;
        newPlaces[5]= true;
        newPlaces[7]= true;
        newPlaces[8]= true;
      break;
      case 'b':
        newPlaces[0]= true;
        newPlaces[1]= true;
        newPlaces[2]= true;
        newPlaces[3]= true;
        newPlaces[4]= true;
        newPlaces[5]= true;
        newPlaces[6]= true;
        newPlaces[7]= true;
        newPlaces[8]= true;
      break;
      }
      setDoneplaces(newPlaces);
  }

  function encode(){
    let secPart = '';
    for(let i = 0; i <= 8; i++){
      if(doneplaces[i]){
        secPart = secPart +'1';
      }
      else{
        secPart = secPart +'0';
      }
    }
    console.log(secPart);
    let secEnc = encodeSecPart('secPart');
    let fstEnc = '';
    for(let i = 8; i >= 0; i--){
      if(doneexercises[i]){
        fstEnc = fstEnc +'1';
      }
      else{
        fstEnc = fstEnc +'0';
      }
    }
    console.log(fstEnc);
    let newEnc = fstEnc + secEnc;
    window.alert(newEnc);
  }

  function encodeSecPart(binArray){
    let newSecPart = 'o';
    switch (binArray) {
      case '100000000':
        newSecPart = 'a';
      break;
      case '010000000':
        newSecPart = 's';
      break;
      case '001000000':
        newSecPart = 'd'; 
      break;
      case '110000000':
        newSecPart = 'f';      
      break;
      case '101000000':
        newSecPart = 'q';      
      break;
      case '011000000':
        newSecPart = 'w';      
      break;
      case '111000000':
        newSecPart = 'e';      
      break;
      case '111100000':
        newSecPart = 'r';     
      break;
      case '111010000':
        newSecPart = 't';      
      break;
      case '111001000':
        newSecPart = 'g';      
      break;
      case '111110000':
        newSecPart = 'y';      
      break;
      case '111101000':
        newSecPart = 'h';      
      break;
      case '111011000':
        newSecPart = 'u';      
      break;
      case '111111000':
        newSecPart = 'j';      
      break;
      case '111111100':
        newSecPart = 'i';      
      break;
      case '111111010':
        newSecPart = 'k';      
      break;
      case '111111001':
        newSecPart = 'z';      
      break;
      case '111111110':
        newSecPart = 'x';      
      break;
      case '111111101':
        newSecPart = 'c';      
      break;
      case '111111011':
        newSecPart = 'v';      
      break;
      case '111111111':
        newSecPart = 'b';      
      break;
    }
    return newSecPart;
  }
 
  function getRandomInt(max) {
    return Math.floor(Math.random() * max);
  }

  function findExercise(level, place){
    let done = false;
    while(!done){
      let extry = getRandomInt(6) + 2;
      if(!doneexercises[extry] && extry!==7){
        setLevel(level);
        setPlace(place);
        setExercise(extry);
        done = true;
      }
    }
  }

    return (
       <div>
        <div style={{float:'right', marginRight:'15px'}}>
        <CodeForm/>
        <button className= "pretButton" onClick={()=>decode()}>submit</button>
        <button className= "pretButton" onClick={()=>encode()}>get Code</button>
        </div>
        <div className='home'>
        <button className= "exButton" disabled={doneplaces[0]} style={{position: 'absolute',top: '1010px', left: '297px' , backgroundColor: doneplaces[0] && 'green'}}onClick={()=>setExercise(1)}>{t('aufgabe')} 1</button>
        <button className= "exButton" disabled={doneplaces[1]} style={{position: 'absolute',top: '830px', left: '305px', backgroundColor: doneplaces[1] && 'green'}}onClick={()=>findExercise(0,1)}>{t('aufgabe')} 2</button>
        <button className= "exButton" disabled={doneplaces[2]} style={{position: 'absolute',top: '700px', left: '500px', backgroundColor: doneplaces[2] && 'green'}}onClick={()=>findExercise(0,2)}>{t('aufgabe')} 3</button>
        <button className= {(doneplaces[0] && doneplaces[1] && doneplaces[2])?"exButton": "exButton_inactive"} disabled={!(doneplaces[0] && doneplaces[1] && doneplaces[2])||doneplaces[3]} style={{position: 'absolute',top: '760px', left: '710px', backgroundColor: doneplaces[3] && 'green'}}onClick={()=>findExercise(1,3)}>{t('aufgabe')} 4</button>
        <button className= {(doneplaces[0] && doneplaces[1] && doneplaces[2])?"exButton": "exButton_inactive"} disabled={!(doneplaces[0] && doneplaces[1] && doneplaces[2])||doneplaces[4]} style={{position: 'absolute',top: '645px', left: '895px', backgroundColor: doneplaces[4] && 'green'}}onClick={()=>findExercise(1,4)}>{t('aufgabe')} 5</button>
        <button className= {(doneplaces[0] && doneplaces[1] && doneplaces[2])?"exButton": "exButton_inactive"} disabled={!(doneplaces[0] && doneplaces[1] && doneplaces[2])||doneplaces[5]} style={{position: 'absolute',top: '440px', left: '810px', backgroundColor: doneplaces[5] && 'green'}}onClick={()=>setExercise(9)}>{t('aufgabe')} 6</button>
        <button className= {(doneplaces[3] && doneplaces[4] && doneplaces[5])?"exButton": "exButton_inactive"} disabled={!(doneplaces[3] && doneplaces[4] && doneplaces[5])||doneplaces[6]} style={{position: 'absolute',top: '340px', left: '520px', backgroundColor: doneplaces[6] && 'green'}}onClick={()=>findExercise(2,6)}>{t('aufgabe')} 7</button>
        <button className= {(doneplaces[3] && doneplaces[4] && doneplaces[5])?"exButton": "exButton_inactive"} disabled={!(doneplaces[3] && doneplaces[4] && doneplaces[5])||doneplaces[7]} style={{position: 'absolute',top: '230px', left: '690px', backgroundColor: doneplaces[7] && 'green'}}onClick={()=>findExercise(2,7)}>{t('aufgabe')} 8</button>
        <button className= {(doneplaces[3] && doneplaces[4] && doneplaces[5])?"exButton": "exButton_inactive"} disabled={!(doneplaces[3] && doneplaces[4] && doneplaces[5])||doneplaces[8]} style={{position: 'absolute',top: '250px', left: '865px', backgroundColor: doneplaces[8] && 'green'}}onClick={()=>setExercise(7)}>{t('aufgabe')} 9</button>
        </div>
        <button className= "pretButton" style={{marginBottom:"10px", marginRight:"10px", float: "right"}} onClick={()=>setExercise(10)}>Credits</button>
      </div>
    )
}
 
export default EX0;