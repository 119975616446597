import React, { useState }  from 'react';
import '../App.css';
import { useTranslation } from 'react-i18next';
import Graph from "react-graph-vis";

//prettierrc odr so fuer guete code

const graphG1 = {
  nodes: [
    { id: 1, label: "Ep1", color: "#e04141" },
    { id: 2, label: "Ep2", color: "#e09c41" },
    { id: 3, label: "Ep3", color: "#e0df41" },
    { id: 4, label: "Ep4", color: "#7be041" },
    { id: 5, label: "Ep5", color: "#41e0c9" },
    { id: 6, label: "Ep6", color: "#7be041" },
    { id: 7, label: "Ep7", color: "#e09c41" },
    { id: 8, label: "Ep8", color: "#41e0c9" },
    { id: 9, label: "Ep9", color: "#41e0c9" },
    { id: 10, label: "Ep10", color: "#41e0c9" }
  ],
  edges: [
    { from: 7, to: 1 },
    { from: 2, to: 3 },
    { from: 4, to: 8 },
    { from: 9, to: 2 },
    { from: 4, to: 6 },
    { from: 9, to: 3 },
    { from: 2, to: 10 },
    { from: 5, to: 6 }
  ]
};
const graphG2 = {
  nodes: [
    { id: 1, label: "Ep1", color: "#e04141" },
    { id: 2, label: "Ep2", color: "#e09c41" },
    { id: 3, label: "Ep3", color: "#e0df41" },
    { id: 4, label: "Ep4", color: "#7be041" },
    { id: 5, label: "Ep5", color: "#41e0c9" },
    { id: 6, label: "Ep6", color: "#7be041" },
    { id: 7, label: "Ep7", color: "#e09c41" },
    { id: 8, label: "Ep8", color: "#41e0c9" },
    { id: 9, label: "Ep9", color: "#41e0c9" },
    { id: 10, label: "Ep10", color: "#41e0c9" }
  ],
  edges: [
    { from: 7, to: 1 },
    { from: 2, to: 3 },
    { from: 4, to: 8 },
    { from: 9, to: 2 },
    { from: 4, to: 6 },
    { from: 9, to: 3 },
    { from: 2, to: 10 },
    { from: 6, to: 5 }
  ]
};
const graphG3 = {
  nodes: [
    { id: 1, label: "Ep1", color: "#e04141" },
    { id: 2, label: "Ep2", color: "#e09c41" },
    { id: 3, label: "Ep3", color: "#e0df41" },
    { id: 4, label: "Ep4", color: "#7be041" },
    { id: 5, label: "Ep5", color: "#41e0c9" },
    { id: 6, label: "Ep6", color: "#7be041" },
    { id: 7, label: "Ep7", color: "#e09c41" },
    { id: 8, label: "Ep8", color: "#41e0c9" },
    { id: 9, label: "Ep9", color: "#41e0c9" },
    { id: 10, label: "Ep10", color: "#41e0c9" }
  ],
  edges: [
    { from: 7, to: 1 },
    { from: 3, to: 2 },
    { from: 4, to: 8 },
    { from: 9, to: 2 },
    { from: 4, to: 6 },
    { from: 9, to: 3 },
    { from: 2, to: 10 },
    { from: 5, to: 6 },
    { from: 9, to: 6 }
  ]
};
const graphM1 = {
  nodes: [
    { id: 1, label: "Ep1", color: "#e04141" },
    { id: 2, label: "Ep2", color: "#e09c41" },
    { id: 3, label: "Ep3", color: "#e0df41" },
    { id: 4, label: "Ep4", color: "#7be041" },
    { id: 5, label: "Ep5", color: "#41e0c9" },
    { id: 6, label: "Ep6", color: "#7be041" },
    { id: 7, label: "Ep7", color: "#e09c41" },
    { id: 9, label: "Ep9", color: "#41e0c9" }
  ],
  edges: [
    { from: 7, to: 1 },
    { from: 2, to: 3 },
    { from: 9, to: 2 },
    { from: 4, to: 6 },
    { from: 9, to: 3 },
    { from: 5, to: 6 }
  ]
};
const graphM2 = {
  nodes: [
    { id: 1, label: "Ep1", color: "#e04141" },
    { id: 2, label: "Ep2", color: "#e09c41" },
    { id: 3, label: "Ep3", color: "#e0df41" },
    { id: 4, label: "Ep4", color: "#7be041" },
    { id: 5, label: "Ep5", color: "#41e0c9" },
    { id: 6, label: "Ep6", color: "#7be041" },
    { id: 7, label: "Ep7", color: "#e09c41" },
    { id: 9, label: "Ep9", color: "#41e0c9" }
  ],
  edges: [
    { from: 7, to: 1 },
    { from: 2, to: 3 },
    { from: 9, to: 2 },
    { from: 4, to: 6 },
    { from: 9, to: 3 },
    { from: 6, to: 5 }
  ]
};
const graphM3 = {
  nodes: [
    { id: 1, label: "Ep1", color: "#e04141" },
    { id: 2, label: "Ep2", color: "#e09c41" },
    { id: 3, label: "Ep3", color: "#e0df41" },
    { id: 4, label: "Ep4", color: "#7be041" },
    { id: 5, label: "Ep5", color: "#41e0c9" },
    { id: 6, label: "Ep6", color: "#7be041" },
    { id: 7, label: "Ep7", color: "#e09c41" },
    { id: 9, label: "Ep9", color: "#41e0c9" }
  ],
  edges: [
    { from: 7, to: 1 },
    { from: 3, to: 2 },
    { from: 9, to: 2 },
    { from: 4, to: 6 },
    { from: 9, to: 3 },
    { from: 5, to: 6 },
    { from: 9, to: 6 }
  ]
};
const graphK1 = {
  nodes: [
    { id: 2, label: "Ep2", color: "#e09c41" },
    { id: 3, label: "Ep3", color: "#e0df41" },
    { id: 4, label: "Ep4", color: "#7be041" },
    { id: 5, label: "Ep5", color: "#41e0c9" },
    { id: 6, label: "Ep6", color: "#7be041" },
    { id: 9, label: "Ep9", color: "#41e0c9" }
  ],
  edges: [
    { from: 2, to: 3 },
    { from: 9, to: 2 },
    { from: 4, to: 6 },
    { from: 9, to: 3 },
    { from: 5, to: 6 }
  ]
};
const graphK2 = {
  nodes: [
    { id: 2, label: "Ep2", color: "#e09c41" },
    { id: 3, label: "Ep3", color: "#e0df41" },
    { id: 4, label: "Ep4", color: "#7be041" },
    { id: 5, label: "Ep5", color: "#41e0c9" },
    { id: 6, label: "Ep6", color: "#7be041" },
    { id: 9, label: "Ep9", color: "#41e0c9" }
  ],
  edges: [
    { from: 2, to: 3 },
    { from: 9, to: 2 },
    { from: 4, to: 6 },
    { from: 9, to: 3 },
    { from: 6, to: 5 }
  ]
};
const graphK3 = {
  nodes: [
    { id: 2, label: "Ep2", color: "#e09c41" },
    { id: 3, label: "Ep3", color: "#e0df41" },
    { id: 4, label: "Ep4", color: "#7be041" },
    { id: 5, label: "Ep5", color: "#41e0c9" },
    { id: 6, label: "Ep6", color: "#7be041" },
    { id: 9, label: "Ep9", color: "#41e0c9" }
  ],
  edges: [
    { from: 3, to: 2 },
    { from: 9, to: 2 },
    { from: 4, to: 6 },
    { from: 9, to: 3 },
    { from: 5, to: 6 },
    { from: 9, to: 6 }
  ]
};


const EX3 = ({place, level, doneexercises, setDoneexercises, doneplaces, setDoneplaces}) => {
  const { t } = useTranslation();
  const [antwort, setAntwort] = useState(0);


  const AntG1 = () => {
    const options = {
      layout: {
        hierarchical: false
      },
      edges: {
        color: "#000000"
      }
    };
    
    return (
      <div>
        <Graph graph={graphG1} options={options} style={{ height: "500px", width:"500px", backgroundColor:"lightgray", borderRadius:"20px", borderColor:"black", borderStyle:"solid"  }} />
      </div>
      );
    }
    const AntG2 = () => {
      const options = {
        layout: {
          hierarchical: false
        },
        edges: {
          color: "#000000"
        }
      };
      
      return (
        <div>
          <Graph graph={graphG2} options={options} style={{ height: "500px", width:"500px", backgroundColor:"lightgray", borderRadius:"20px", borderColor:"black", borderStyle:"solid"  }} />
        </div>
        );
    }
    const AntG3 = () => {
      const options = {
        layout: {
          hierarchical: false
        },
        edges: {
          color: "#000000"
        }
      };
      
      return (
        <div>
          <Graph graph={graphG3} options={options} style={{ height: "500px", width:"500px", backgroundColor:"lightgray", borderRadius:"20px", borderColor:"black", borderStyle:"solid"  }} />
        </div>
        );
    }
    const AntM1 = () => {
      const options = {
        layout: {
          hierarchical: false
        },
        edges: {
          color: "#000000"
        }
      };
      
      return (
        <div>
          <Graph graph={graphM1} options={options} style={{ height: "500px", width:"500px", backgroundColor:"lightgray", borderRadius:"20px", borderColor:"black", borderStyle:"solid"  }} />
        </div>
        );
      }
    const AntM2 = () => {
      const options = {
        layout: {
          hierarchical: false
        },
        edges: {
          color: "#000000"
        }
      };
      
      return (
        <div>
          <Graph graph={graphM2} options={options} style={{ height: "500px", width:"500px", backgroundColor:"lightgray", borderRadius:"20px", borderColor:"black", borderStyle:"solid"  }} />
        </div>
        );
    }
    const AntM3 = () => {
      const options = {
        layout: {
          hierarchical: false
        },
        edges: {
          color: "#000000"
        }
      };
      
      return (
        <div>
          <Graph graph={graphM3} options={options} style={{ height: "500px", width:"500px", backgroundColor:"lightgray", borderRadius:"20px", borderColor:"black", borderStyle:"solid"  }} />
        </div>
        );
    }
    const AntK1 = () => {
      const options = {
        layout: {
          hierarchical: false
        },
        edges: {
          color: "#000000"
        }
      };
      
      return (
        <div>
          <Graph graph={graphK1} options={options} style={{ height: "500px", width:"500px", backgroundColor:"lightgray", borderRadius:"20px", borderColor:"black", borderStyle:"solid"  }} />
        </div>
        );
      }
    const AntK2 = () => {
      const options = {
        layout: {
          hierarchical: false
        },
        edges: {
          color: "#000000"
        }
      };
      
      return (
        <div>
          <Graph graph={graphK2} options={options} style={{ height: "500px", width:"500px", backgroundColor:"lightgray", borderRadius:"20px", borderColor:"black", borderStyle:"solid"  }} />
        </div>
        );
    }
    const AntK3 = () => {
      const options = {
        layout: {
          hierarchical: false
        },
        edges: {
          color: "#000000"
        }
      };
      
      return (
        <div>
          <Graph graph={graphK3} options={options} style={{ height: "500px", width:"500px", backgroundColor:"lightgray", borderRadius:"20px", borderColor:"black", borderStyle:"solid"  }} />
        </div>
        );
      } 
  const Ruletry2 = () => {
    return (
      <div>
        <div> 
        <ol>
        {level>=1 &&<li>{t('r1EX3')}</li>}
        <li style={{color: antwort===2 && 'red', fontWeight: antwort===2 && 'bold'}}>{t('r4EX3')}</li>
        {level===2 &&<li>{t('r7EX3')}</li>}
        <li>{t('r3EX3')}</li>
        <li>{t('r6EX3')}</li>
        <li>{t('r2EX3')}</li>
        {level===2 &&<li>{t('r5EX3')}</li>}
        <li style={{color: antwort===1 && 'red', fontWeight: antwort===1 && 'bold'}}>{t('r8EX3')}</li>
        {antwort ===2 && <li style={{color: 'red', fontWeight:  'bold', fontStyle:"italic"}}>{t('trEX3')}</li>}
        </ol>
      </div>
      </div>
    );
  }
  function correct(){
    setAntwort(3);
    let prevex = doneexercises;
    prevex[2]= true;
    setDoneexercises(prevex);
    let prevplace = doneplaces;
    prevplace[place]= true;
    setDoneplaces(prevplace);
    window.alert("Good Job!");
  }
  
  return (
    <div className='exercises'>
      <p>{t('intrEX3')}</p>
        <Ruletry2/>
      <p>{t('fragEX3')}</p>
      <div style={{display: 'flex', alignItems:'center',flexDirection:'row', justifyContent:'center'}}>
        <div style={{display: 'flex', flexDirection:'column', alignItems:'center',justifyContent:'center'}}>
          {level ===2 && <AntG1/>}
          {level ===1 && <AntM1/>}
          {level ===0 && <AntK1/>}
          <p><label ><input type="radio" checked={antwort === 3} onClick={correct}/>{t('graph')} 1</label></p>
        </div>
        <div style={{display: 'flex', flexDirection:'column', alignItems:'center',justifyContent:'center'}}>
          {level ===2 && <AntG2/>}
          {level ===1 && <AntM2/>}
          {level ===0 && <AntK2/>}
          <p><label ><input type="radio" checked={antwort === 1} onClick={()=>setAntwort(1)}/>{t('graph')} 2</label></p>
        </div>
        <div style={{display: 'flex', flexDirection:'column', alignItems:'center',justifyContent:'center'}}>
          {level ===2 && <AntG3/>}
          {level ===1 && <AntM3/>}
          {level ===0 && <AntK3/>}
          <p> <label ><input type="radio" checked={antwort === 2} onClick={()=>setAntwort(2)}/>{t('graph')} 3</label></p>
        </div>
        </div>
    </div>
  );
}

export default EX3;
