import React, { useState }  from 'react';
import '../App.css';
import { useTranslation } from 'react-i18next';


const EX9 = ({doneexercises, setDoneexercises, doneplaces, setDoneplaces}) => {
  const { t } = useTranslation();
  const [values, setValues] = useState(["","","","",""])
  const options = ["+", "-", "*","/","Digits"]
  const [correctedges, setCorrectedges] = useState([false,false,false,false,false,false,false,false])
  const [added, setAdded] = useState(0);
  const [darstellung, setDarstellung] = useState(0);
  const [answered, setAnswered] = useState(0);

  const Tabeletry = () => {
    return (
     <table style={{width: '800px', height: '200px'}}>
      <tr>
        <td></td>
        <td>+</td>
        <td>-</td>
        <td>/</td>
        <td>*</td>
        <td>{t('digits')}</td>
      </tr>
      <tr>
        <td>+</td>
        <td></td>
        <td style={{color: (answered>=1 && !correctedges[2])&& 'red', fontWeight: (answered>=1 && !correctedges[2]) && 'bold'}}>x</td>
        <td></td>
        <td style={{color: (answered>=1 && !correctedges[4])&& 'red', fontWeight: (answered>=1 && !correctedges[4]) && 'bold'}}>x</td>
        <td></td>
      </tr>
      <tr>
        <td>-</td>
        <td></td>
        <td></td>
        <td style={{color: (answered>=1 && !correctedges[6])&& 'red', fontWeight: (answered>=1 && !correctedges[6]) && 'bold'}}>x</td>
        <td></td>
        <td></td>
      </tr>
      <tr>
        <td>/</td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
      </tr>
      <tr>
        <td>*</td>
        <td></td>
        <td></td>
        <td style={{color: (answered>=1 && !correctedges[7])&& 'red', fontWeight: (answered>=1 && !correctedges[7]) && 'bold'}}>x</td>
        <td></td>
        <td></td>
      </tr>
      <tr>
        <td>{t('digits')}</td>
        <td style={{color: (answered>=1 && !correctedges[0])&& 'red', fontWeight: (answered>=1 && !correctedges[0]) && 'bold'}}>x</td>
        <td style={{color: (answered>=1 && !correctedges[1])&& 'red', fontWeight: (answered>=1 && !correctedges[1]) && 'bold'}}>x</td>
        <td style={{color: (answered>=1 && !correctedges[3])&& 'red', fontWeight: (answered>=1 && !correctedges[3]) && 'bold'}}>x</td>
        <td style={{color: (answered>=1 && !correctedges[5])&& 'red', fontWeight: (answered>=1 && !correctedges[5]) && 'bold'}}>x</td>
        <td></td>
      </tr>
      </table>
      );

  }
  const Ruletry = () => {

    return (
      <div>
        <ol>
        <li style={{color: (answered>=1 && !correctedges[0])&& 'red', fontWeight: (answered>=1 && !correctedges[0]) && 'bold'}}>{t('r1EX9')}</li>
        <li style={{color: (answered>=1 && !correctedges[1])&& 'red', fontWeight: (answered>=1 && !correctedges[1]) && 'bold'}}>{t('r3EX9')}</li>
        <li style={{color: (answered>=1 && !correctedges[2])&& 'red', fontWeight: (answered>=1 && !correctedges[2]) && 'bold'}}>{t('r6EX9')}</li>
        <li style={{color: (answered>=1 && !correctedges[3])&& 'red', fontWeight: (answered>=1 && !correctedges[3]) && 'bold'}}>{t('r4EX9')}</li>
        <li style={{color: (answered>=1 && !correctedges[4])&& 'red', fontWeight: (answered>=1 && !correctedges[4]) && 'bold'}}>{t('r5EX9')}</li>
        <li style={{color: (answered>=1 && !correctedges[5])&& 'red', fontWeight: (answered>=1 && !correctedges[5]) && 'bold'}}>{t('r2EX9')}</li>
        <li style={{color: (answered>=1 && !correctedges[6])&& 'red', fontWeight: (answered>=1 && !correctedges[6]) && 'bold'}}>{t('r0EX9')}</li>
        <li style={{color: (answered>=1 && !correctedges[7])&& 'red', fontWeight: (answered>=1 && !correctedges[7]) && 'bold'}}>{t('r7EX9')}</li>
        </ol>
      </div>
    );
  }
  function correct(){
    let allcorrect = true;
    let goodedge = [false,false,false,false,false,false,false,false];
    for(let x = 1; x <= 4; x++){
      for(let z = 0; z < x; z++){
        if(values[x]==="+"){
          if(values[z]==="Digits"){
            goodedge[0] = true;
          }
        }
        if(values[x]==="-"){
          if(values[z]==="Digits"){
            goodedge[1] = true;
          }
          if(values[z]==="+"){
            goodedge[2] = true;
          }
        }
        if(values[x]==="*"){
          if(values[z]==="Digits"){
            goodedge[3] = true;
          }
          if(values[z]==="+"){
            goodedge[4] = true;
          }
        }
        if(values[x]==="/"){
          if(values[z]==="Digits"){
            goodedge[5] = true;
          }
          if(values[z]==="-"){
            goodedge[6] = true;
          }
          if(values[z]==="*"){
            goodedge[7] = true;
          }
        }
      }
    }
    setCorrectedges(goodedge);
    for(let x = 0; x <= 7; x++){
      if(!correctedges[x]){
        allcorrect = false;
      }
    } 
    setAnswered(answered+1);
    if(allcorrect){
      let prevex = doneexercises;
      prevex[8]= true;
      setDoneexercises(prevex);
      let prevplace = doneplaces;
      prevplace[5]= true;
      setDoneplaces(prevplace);
      window.alert("Good Job!");
    }
  }

  return (
    <div className='exercises'>
      <p>{t('intrEX9')}</p>
      <div style={{display: 'flex', alignItems:'center',flexDirection:'row', justifyContent:'center'}}>
          {darstellung === 0 && <Ruletry/>}
          {darstellung === 2  && <Tabeletry/>}
      </div>
      <button className= "pretButton" onClick={()=>setDarstellung(0)}>{t('text')}</button>
      <button className= "pretButton" onClick={()=>setDarstellung(2)}>{t('table')}</button>
     <p>{t('fragEX7')}</p>
      <div style={{display: 'flex', flexDirection:'row', alignItems:'center', justifyContent:'center'}}>
           {values.map((v, i) => (
              <div style={{ width: 100, height: 30, borderRadius: "10%", backgroundColor: "green", margin: 2, color:'white' }} key={i}>
                {v}
              </div>
            ))}
          </div>
          <div>
            {options.map((v, i) => (
              <button
                className= "pretButton"
                m="sm"
                variant='outline'
                key={i}
                onClick={() => {
                  if (added < options.length)
                    setValues([...values.slice(0, added), options[i], ...values.slice(added + 1)]);
                  setAdded(a => Math.min(a + 1, options.length))
                }}
              >
                {v}
              </button>
            ))}
          </div>                                           
      <button className= "pretButton" onClick={()=>{setValues([...values.map((v,i)=>( i >= added-1 ? "": v))]); setAdded(a=>Math.max(a-1, 0))}}>{t('clear')}</button>     
      <button className= "pretButton" onClick={()=>correct()}>{t('correct')}</button> 
    </div>
  );
}

export default EX9;