import React, { useState }  from 'react';
import '../App.css';
import { useTranslation } from 'react-i18next';
import Graph from "react-graph-vis";
 
//make ai do icons for translations
const graphGross = {
  nodes: [
    { id: 1, label: "Buerste", color: "#e04141" },
    { id: 2, label: "Ballon", color: "#e09c41" },
    { id: 3, label: "Fahrrad", color: "#e0df41" },
    { id: 4, label: "Boot", color: "#7be041" },
    { id: 5, label: "Hund", color: "#41e0c9" },
    { id: 6, label: "Teppich", color: "#7be041" },
    { id: 7, label: "Korb", color: "#e09c41" },
    { id: 8, label: "Haus", color: "#41e0c9" },
    { id: 9, label: "Motorrad", color: "#41e0c9" }
  ],
  edges: [
    { from: 1, to: 2 },
    { from: 2, to: 3 },
    { from: 2, to: 4 },
    { from: 5, to: 6 },
    { from: 6, to: 8 },
    { from: 7, to: 5 },
    { from: 1, to: 7 },
    { from: 3, to: 9 },
    { from: 6, to: 9 },
    { from: 4, to: 9 }
  ]
};

const graphMittel = {
  nodes: [
    { id: 1, label: "Buerste", color: "#e04141" },
    { id: 2, label: "Ballon", color: "#e09c41" },
    { id: 3, label: "Fahrrad", color: "#e0df41" },
    { id: 5, label: "Hund", color: "#41e0c9" },
    { id: 6, label: "Teppich", color: "#7be041" },
    { id: 7, label: "Korb", color: "#e09c41" },
    { id: 9, label: "Motorrad", color: "#41e0c9" }
  ],
  edges: [
    { from: 1, to: 2 },
    { from: 2, to: 3 },
    { from: 5, to: 6 },
    { from: 7, to: 5 },
    { from: 1, to: 7 },
    { from: 3, to: 9 },
    { from: 6, to: 9 }
  ]
};

const graphKlein = {
  nodes: [
    { id: 1, label: "Buerste", color: "#e04141" },
    { id: 2, label: "Ballon", color: "#e09c41" },
    { id: 3, label: "Fahrrad", color: "#e0df41" },
    { id: 6, label: "Teppich", color: "#7be041" },
    { id: 9, label: "Motorrad", color: "#41e0c9" }
  ],
  edges: [
    { from: 1, to: 2 },
    { from: 2, to: 3 },
    { from: 3, to: 9 },
    { from: 6, to: 9 }
  ]
};
const EX6 = ({place, level, doneexercises, setDoneexercises, doneplaces, setDoneplaces}) => {
  const { t } = useTranslation();
  const [antwort, setAntwort] = useState(0);

  const TauschhandelG = () => {
    const options = {
      layout: {
        hierarchical: false
      },
      edges: {
        color: "#000000"
      }
    };
    return (
      <div>
        <Graph graph={graphGross} options={options} style={{ height: "500px", width:"500px", backgroundColor:"lightgray", borderRadius:"20px", borderColor:"black", borderStyle:"solid"  }} />
      </div>
      );
    }
    const TauschhandelM = () => {
      const options = {
        layout: {
          hierarchical: false
        },
        edges: {
          color: "#000000"
        }
      };
      return (
        <div>
          <Graph graph={graphMittel} options={options} style={{ height: "500px", width:"500px", backgroundColor:"lightgray", borderRadius:"20px", borderColor:"black", borderStyle:"solid"  }} />
        </div>
        );
      }
      const TauschhandelK = () => {
        const options = {
          layout: {
            hierarchical: false
          },
          edges: {
            color: "#000000"
          }
        };
        return (
          <div>
            <Graph graph={graphKlein} options={options} style={{ height: "500px", width:"500px", backgroundColor:"lightgray", borderRadius:"20px", borderColor:"black", borderStyle:"solid"  }} />
          </div>
          );
        }
  const TauschhandelG2 = () => {
    const options = {
      layout: {
        hierarchical: false
      },
      edges: {
        color: "#000000"
      }
    };
    const graph = {
      nodes: [
        { id: 1, label: "Buerste", color: "#e04141" },
        { id: 2, label: "Ballon", color: "#e09c41" },
        { id: 3, label: "Fahrrad", color: "#e0df41" },
        { id: 4, label: "Boot", color: "#7be041" },
        { id: 5, label: "Hund", color: "#41e0c9" },
        { id: 6, label: "Teppich", color: "#7be041" },
        { id: 7, label: "Korb", color: "#e09c41" },
        { id: 8, label: "Haus", color: "#41e0c9" },
        { id: 9, label: "Motorrad", color: "#41e0c9" }
      ],
      edges: [
        { from: 1, to: 2 },
        { from: 2, to: 3 },
        { from: 2, to: 4 },
        { from: 5, to: 6 },
        { from: 6, to: 8 },
        { from: 7, to: 5 },
        { from: 1, to: 7, color: '#e04141', value: 1 },
        { from: 7, to: 2, color: '#e04141', dashes: [5,12], value: 1},
        { from: 3, to: 9 },
        { from: 6, to: 9, color: '#e04141', value: 1 },
        { from: 4, to: 9 }
      ]
    };
    return (
      <div>
        <Graph graph={graph} options={options} style={{ height: "500px", width:"500px", backgroundColor:"lightgray", borderRadius:"20px", borderColor:"black", borderStyle:"solid"  }} />
      </div>
      );
    }

    const TauschhandelM2 = () => {
      const options = {
        layout: {
          hierarchical: false
        },
        edges: {
          color: "#000000"
        }
      };
      const graph = {
        nodes: [
          { id: 1, label: "Buerste", color: "#e04141" },
          { id: 2, label: "Ballon", color: "#e09c41" },
          { id: 3, label: "Fahrrad", color: "#e0df41" },
          { id: 5, label: "Hund", color: "#41e0c9" },
          { id: 6, label: "Teppich", color: "#7be041" },
          { id: 7, label: "Korb", color: "#e09c41" },
          { id: 9, label: "Motorrad", color: "#41e0c9" }
        ],
        edges: [
          { from: 1, to: 2 },
          { from: 2, to: 3 },
          { from: 5, to: 6 },
          { from: 7, to: 5 },
          { from: 1, to: 7, color: '#e04141', value: 1 },
          { from: 7, to: 2, color: '#e04141', dashes: [5,12], value: 1},
          { from: 3, to: 9 },
          { from: 6, to: 9, color: '#e04141', value: 1 }
        ]
      };
      return (
        <div>
          <Graph graph={graph} options={options} style={{ height: "500px", width:"500px", backgroundColor:"lightgray", borderRadius:"20px", borderColor:"black", borderStyle:"solid"  }} />
        </div>
        );
      }
      const TauschhandelK2 = () => {
        const options = {
          layout: {
            hierarchical: false
          },
          edges: {
            color: "#000000"
          }
        };
        const graph = {
          nodes: [
            { id: 1, label: "Buerste", color: "#e04141" },
            { id: 2, label: "Ballon", color: "#e09c41" },
            { id: 3, label: "Fahrrad", color: "#e0df41" },
            { id: 6, label: "Teppich", color: "#7be041" },
            { id: 9, label: "Motorrad", color: "#41e0c9" }
          ],
          edges: [
            { from: 1, to: 2 },
            { from: 2, to: 3 },
            { from: 3, to: 9 },
            { from: 6, to: 9, color: '#e04141', value: 1 }
          ]
        };
        return (
          <div>
            <Graph graph={graph} options={options} style={{ height: "500px", width:"500px", backgroundColor:"lightgray", borderRadius:"20px", borderColor:"black", borderStyle:"solid"  }} />
          </div>
          );
        }
  const Ruletry2 = () => {
    return (
      <div>
        <div>
        <ol>
        <li>{t('r2EX6')}</li>
        <li>{t('r1EX6')}</li>
        {level ===2 &&<li>{t('r10EX6')}</li>}
        {level >=1 &&<li>{t('r11EX6')}</li>}
        {level ===2 &&<li>{t('r3EX6')}</li>}
        {level >=1 &&<li>{t('r4EX6')}</li>}
        {level >=1 &&<li>{t('r5EX6')}</li>}
        <li>{t('r6EX6')}</li>
        <li>{t('r7EX6')}</li>
        {level ===2 &&<li>{t('r8EX6')}</li>}
        </ol>
      </div>
      </div>
    );
  }
  const Ruletry1 = () => {
    return (
      <div>
        <div> 
        <ol>
        {level >=1 &&<li>{t('fr11EX6')}</li>}
        {level >=1 &&<li>{t('r4EX6')}</li>}
        <li>{t('r1EX6')}</li>
        {level ===2 &&<li>{t('r10EX6')}</li>}
        {level ===2 &&<li>{t('r3EX6')}</li>}
        {level >=1 &&<li>{t('r5EX6')}</li>}
        <li>{t('r2EX6')}</li>
        {level ===2 &&<li>{t('r8EX6')}</li>}
        <li>{t('fr6EX6')}</li>
        <li>{t('r7EX6')}</li>
        </ol>
      </div>
      </div>
    );
  }
  const Ruletry3 = () => {
    return (
      <div>
        <div> 
        <ol>
        <li>{t('r1EX6')}</li>
        <li>{t('r2EX6')}</li>
        {level >=1 &&<li>{t('r11EX6')}</li>}
        {level ===2 &&<li>{t('r3EX6')}</li>}
        {level ===2 &&<li>{t('r10EX6')}</li>}
        {level >=1 &&<li>{t('fr5EX6')}</li>}
        <li>{t('r6EX6')}</li>
        {level ===2 &&<li>{t('r8EX6')}</li>}
        {level >=1 &&<li>{t('r4EX6')}</li>}
        </ol>
      </div>
      </div>
    );
  }
  function correct(){
    graphGross.edges[3].color="black";
    graphGross.edges[3].value=undefined;
    graphGross.edges[7].color="black";
    graphGross.edges[7].value=undefined;
    graphKlein.edges[2].color="black";
    graphKlein.edges[2].value=undefined;
    graphMittel.edges[2].color="black";
    graphMittel.edges[2].value=undefined;
    graphMittel.edges[5].color="black";
    graphMittel.edges[5].value=undefined;
    setAntwort(3);
    let prevex = doneexercises;
    prevex[5]= true;
    setDoneexercises(prevex);
    let prevplace = doneplaces;
    prevplace[place]= true;
    setDoneplaces(prevplace);
    window.alert("Good Job!");
  }
  function wrong1(){
    graphGross.edges[3].color="red";
    graphGross.edges[3].value=1;
    graphGross.edges[7].color="red";
    graphGross.edges[7].value=1;
    graphKlein.edges[2].color="red";
    graphKlein.edges[2].value=1;
    graphMittel.edges[2].color="red";
    graphMittel.edges[2].value=1;
    graphMittel.edges[5].color="red";
    graphMittel.edges[5].value=1;
    setAntwort(1);
  }
  return (
    <div className='exercises'>
      <p>{t('intrEX6')}</p>
      <div style={{display: 'flex', alignItems:'center',flexDirection:'row', justifyContent:'center'}}>
        {(antwort !== 2 && level === 2) && <TauschhandelG/>}
        {(antwort !== 2 && level === 1) && <TauschhandelM/>}
        {(antwort !== 2 && level === 0) && <TauschhandelK/>}
        {(antwort === 2 && level === 2) && <TauschhandelG2/>}
        {(antwort === 2 && level === 1) && <TauschhandelM2/>}
        {(antwort === 2 && level === 0) && <TauschhandelK2/>}
      </div>
      <p>{t('fragEX6')}</p>
      <div style={{display: 'flex', alignItems:'center',flexDirection:'row', justifyContent:'center'}}>
        <div style={{display: 'flex', flexDirection:'column', alignItems:'center',justifyContent:'center'}}>
          <Ruletry1/>
          <p><label ><input type="radio" checked={antwort === 1} onClick={()=>wrong1()}/>{t('regeln')} 1</label></p>
        </div>
        <div style={{display: 'flex', flexDirection:'column', alignItems:'center',justifyContent:'center'}}>
          <Ruletry2/>
          <p><label ><input type="radio" checked={antwort === 3} onClick={correct}/>{t('regeln')} 2</label></p>
        </div>
        <div style={{display: 'flex', flexDirection:'column', alignItems:'center',justifyContent:'center'}}>
          <Ruletry3/>
          <p> <label ><input type="radio" checked={antwort === 2} onClick={()=>setAntwort(2)}/>{t('regeln')} 3</label></p>
        </div>
      </div>
    </div>
  );
}

export default EX6;